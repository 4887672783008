import React, { FC, useState, useEffect } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridHasMap from "./SectionGridHasMap";
import SectionGridFilterCard from "./SectionGridFilterCard";
import TabFilters from "./TabFilters/TabFilters";
import { Helmet } from "react-helmet-async";
import { startTransition } from "react";

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1280);
  const [isFullScreenMap, setIsFullScreenMap] = useState(false); // Estado global para el mapa de pantalla completa

  const handleToggleMap = () => {
    startTransition(() => {
      setIsMapVisible(!isMapVisible);
    });
  };

  const handleResize = () => {
    const isNowLargeScreen = window.innerWidth >= 1280;
    setIsLargeScreen(isNowLargeScreen);

    // Cerrar el mapa si la pantalla es pequeña y el mapa está visible
    if (!isNowLargeScreen && isMapVisible) {
      setIsMapVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMapVisible]);

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>
          Bedlia - Hoteles, Apartamentos, Habitaciones, Experiencias y mucho
          más...
        </title>
      </Helmet>
      <BgGlassmorphism />

      {/* Vista normal */}
      <div
        className={`${isMapVisible ? "w-full h-full" : "container max-w-6xl mx-auto"
          } pb-24 lg:pb-28 2xl:pl-10 xl:pr-0`}
      >

        {isMapVisible ? (
          <SectionGridHasMap
            isMapVisible={isMapVisible}
            onToggleMap={handleToggleMap}
          />
        ) : (
          <SectionGridFilterCard
            className="pb-24 lg:pb-28"
            onToggleMap={isLargeScreen ? handleToggleMap : undefined}
            isMapVisible={isMapVisible}
          />
        )}
      </div>
    </div>
  );
};

export default ListingStayMapPage;