import React, { FC, useState } from "react";
import SectionGridHasMap from "./SectionGridHasMap";
import MapSearch from "./MapSearch";
import MoreFiltersMobile from "./TabFilters/MoreFiltersMobile";

interface FullScreenStaysMapProps {
  isMapVisible: boolean;
  onClose: () => void;
  mapCenter: [number, number];
  setMapCenter: (coordinates: [number, number]) => void;
}

const FullScreenStaysMap: FC<FullScreenStaysMapProps> = ({
  isMapVisible,
  onClose,
  mapCenter,
  setMapCenter,
}) => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  if (!isMapVisible) return null;

  return (
    <div className="fixed inset-0 z-50 bg-white">
      {/* Controles superiores */}
      <div className="absolute top-4 left-14 right-4 flex items-center justify-between z-[60]">
        {/* Botón para abrir los filtros */}
        <button
          onClick={() => setIsFiltersModalOpen(true)} // Abre el modal de filtros
          className="bg-white border border-gray-300 text-gray-500 w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
          aria-label="Open filters"
        >
          <i className="las la-sliders-h text-2xl font-bold"></i>
        </button>

        {/* Barra de búsqueda */}
        <div className="flex-1 mx-4">
          <MapSearch
            onLocationSelect={(coordinates) => {
              setMapCenter(coordinates); // Navega a las coordenadas seleccionadas
            }}
          />
        </div>

        {/* Botón para cerrar el mapa */}
        <button
          onClick={onClose}
          className="bg-gray-500 text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
          aria-label="Close map"
        >
          <span className="text-xl font-bold">✕</span>
        </button>
      </div>

      {/* Contenedor del mapa */}
      <div className="w-full h-full">
        <SectionGridHasMap
          isMapVisible={true} // Asegura que el mapa esté visible
          onToggleMap={onClose} // Lógica para cerrar el mapa
          mapCenter={mapCenter}
        />
      </div>

      {/* Modal de filtros móviles */}
      <MoreFiltersMobile
        isOpen={isFiltersModalOpen}
        closeModal={() => setIsFiltersModalOpen(false)} // Cierra el modal
        selectedAmenities={[]} // Actualizar según el contexto
        selectedFacilities={[]} // Actualizar según el contexto
        onApply={(amenities, facilities) => {
          console.log("Filtros aplicados:", { amenities, facilities });
          setIsFiltersModalOpen(false); // Cierra el modal después de aplicar
        }}
        onClear={() => {
          console.log("Filtros limpiados");
        }}
      />
    </div>
  );
};

export default FullScreenStaysMap;
