import React, { FC, useState } from "react";
import locationsData from "./locationStaysData.json";

interface Location {
  name: string;
  coordinates: [number, number];
}

const MapSearch: FC<{ onLocationSelect: (coordinates: [number, number]) => void }> = ({
  onLocationSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<Location[]>([]);

  // Map the data to enforce correct typing
  const locations: Location[] = locationsData.locations.map((loc) => {
    if (loc.coordinates.length !== 2) {
      throw new Error(`Invalid coordinates for location: ${loc.name}`);
    }
    return {
      name: loc.name,
      coordinates: [loc.coordinates[0], loc.coordinates[1]] as [number, number],
    };
  });

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
    if (value.trim()) {
      setSuggestions(
        locations.filter((loc) => loc.name.toLowerCase().includes(value.toLowerCase()))
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (location: Location) => {
    setSearchTerm(location.name); // Actualiza el input con el nombre seleccionado
    setSuggestions([]); // Limpia las sugerencias
    onLocationSelect(location.coordinates); // Llama a onLocationSelect con las coordenadas seleccionadas
  };

  return (
    <div className="relative flex flex-col space-y-2">
      <input
        type="text"
        className="w-full px-4 py-2 rounded-full border border-gray-300 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Buscar en el mapa"
        value={searchTerm}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      {suggestions.length > 0 && (
        <ul className="absolute top-full mt-1 bg-white border border-gray-300 rounded-lg shadow-md w-full z-10">
          {suggestions.map((location) => (
            <li
              key={location.name}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSuggestionClick(location)}
            >
              {location.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MapSearch;