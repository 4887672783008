import React, { FC, useState } from "react";
import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next"; // Importar useTranslation
import ButtonPrimary from "shared/Button/ButtonPrimary"; // Importar ButtonPrimary

interface BedsAndBathroomsFiltersProps {
  setBedroomsCount: (count: number) => void;
  setBathroomsCount: (count: number) => void;
}

const BedsAndBathroomsFilters: FC<BedsAndBathroomsFiltersProps> = ({
  setBedroomsCount,
  setBathroomsCount,
}) => {
  const { t } = useTranslation("bedsAndBathroomsFilters"); // Usar el namespace para traducción
  const [bedrooms, setBedrooms] = useState(0); // Cambiar de 'guests' a 'bedrooms'
  const [bathrooms, setBathrooms] = useState(0); // Cambiar de 'beds' a 'bathrooms'

  const handleIncrementBedrooms = () => {
    setBedrooms(bedrooms + 1);
  };

  const handleDecrementBedrooms = () => {
    if (bedrooms > 0) {
      setBedrooms(bedrooms - 1);
    }
  };

  const handleIncrementBathrooms = () => {
    setBathrooms(bathrooms + 1);
  };

  const handleDecrementBathrooms = () => {
    if (bathrooms > 0) {
      setBathrooms(bathrooms - 1);
    }
  };

  const handleApply = () => {
    setBedroomsCount(bedrooms);
    setBathroomsCount(bathrooms);
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none">
            <span>{t("bedsAndBathrooms")}</span> {/* Título del botón */}
          </Popover.Button>
          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0">
            <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
              <div className="relative flex flex-col px-5 py-6 space-y-8">
                <div className="flex justify-between items-center">
                  <span>{t("bedrooms")}</span>
                  <div className="flex items-center">
                    <button onClick={handleDecrementBedrooms} className="px-2 border">−</button>
                    <span className="mx-2">{bedrooms}</span>
                    <button onClick={handleIncrementBedrooms} className="px-2 border">+</button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span>{t("bathrooms")}</span>
                  <div className="flex items-center">
                    <button onClick={handleDecrementBathrooms} className="px-2 border">−</button>
                    <span className="mx-2">{bathrooms}</span>
                    <button onClick={handleIncrementBathrooms} className="px-2 border">+</button>
                  </div>
                </div>
              </div>
              <div className="p-5 bg-neutral-50 flex items-center justify-between">
                <button 
                  onClick={() => { 
                    setBedrooms(0); 
                    setBathrooms(0); 
                  }} 
                  className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full text-sm sm:text-base font-medium px-4 py-2 border border-neutral-200 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0">
                  {t("clear")}
                </button>
                <ButtonPrimary 
                  onClick={handleApply} 
                  sizeClass="px-4 py-2 sm:px-5"
                >
                  {t("apply")}
                </ButtonPrimary>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default BedsAndBathroomsFilters;
