import React, { useState } from "react";
import GuestsInput from "../GuestsInput";
import LocationInput from "../LocationInput";
import DatesRangeInput from "../DatesRangeInput";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, es } from "date-fns/locale";

interface StaySearchFormProps {
  tempLocation: string;
  setTempLocation: React.Dispatch<React.SetStateAction<string>>;
  tempFrom: Date | null;
  tempTo: Date | null;
  setTempFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  setTempTo: React.Dispatch<React.SetStateAction<Date | null>>;
  tempAdults: number;
  setTempAdults: React.Dispatch<React.SetStateAction<number>>;
  tempChildren: number;
  setTempChildren: React.Dispatch<React.SetStateAction<number>>;
  tempInfants: number;
  setTempInfants: React.Dispatch<React.SetStateAction<number>>;
  onSearch: (data: any) => void;
}

const StaySearchForm: React.FC<StaySearchFormProps> = ({
  tempLocation,
  setTempLocation,
  tempFrom,
  setTempFrom,
  tempTo,
  setTempTo,
  tempAdults,
  setTempAdults,
  tempChildren,
  setTempChildren,
  tempInfants,
  setTempInfants,
  onSearch,
}) => {
  const { t, i18n } = useTranslation("guestsInput");
  const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates" | "guests">("location");
  const [isDatesValid, setIsDatesValid] = useState(false);

  // Define el locale dinámico basado en el idioma actual
  const currentLocale = i18n.language === "es" ? es : enUS;

  // Formatear fechas dinámicamente según el idioma (día y mes abreviado)
  const formatSelectedDates = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) {
      return t("addDate");
    }
    return `${format(startDate, "dd MMM", { locale: currentLocale })} - ${format(endDate, "dd MMM", {
      locale: currentLocale,
    })}`;
  };

  const renderInputLocation = () => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        fieldNameShow === "location" ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow("location")}
      >
        <span className="text-neutral-400">{t("location")}</span>
        <span>{tempLocation || t("addLocation")}</span>
      </button>
      {fieldNameShow === "location" && (
        <LocationInput
          headingText={t("whereTo")}
          tempLocation={tempLocation}
          setTempLocation={setTempLocation}
        />
      )}
    </div>
  );

  const renderInputDates = () => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        fieldNameShow === "dates" ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow("dates")}
      >
        <span className="text-neutral-400">{t("when")}</span>
        <span>{formatSelectedDates(tempFrom, tempTo)}</span>
      </button>
      {fieldNameShow === "dates" && (
        <DatesRangeInput
          tempStartDate={tempFrom}
          tempEndDate={tempTo}
          setTempStartDate={setTempFrom}
          setTempEndDate={setTempTo}
          setIsDatesValid={setIsDatesValid}
        />
      )}
    </div>
  );

  const renderInputGuests = () => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        fieldNameShow === "guests" ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow("guests")}
      >
        <span className="text-neutral-400">{t("who")}</span>
        <span>{`${tempAdults + tempChildren} ${t("guests")}`}</span>
      </button>
      {fieldNameShow === "guests" && (
        <GuestsInput
          tempAdults={tempAdults}
          tempChildren={tempChildren}
          tempInfants={tempInfants}
          setTempAdults={setTempAdults}
          setTempChildren={setTempChildren}
          setTempInfants={setTempInfants}
        />
      )}
    </div>
  );

  return (
    <div className="w-full space-y-5">
      {renderInputLocation()}
      {renderInputDates()}
      {renderInputGuests()}
    </div>
  );
};

export default StaySearchForm;
