import React, { FC, useState } from "react";
import ExperienceGuestsInput from "../ExperienceGuestsInput";
import LocationInputExperiences from "../LocationInputExperiences";
import ExperienceDatesRangeInput from "../ExperienceDatesRangeInput";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext"; // Contexto de ubicación para experiencias
import { useDatesContext } from "contexts/DatesContext"; // Contexto de fechas

interface ExperienceSearchFormProps {
  tempExperienceLocation: string;
  setTempExperienceLocation: React.Dispatch<React.SetStateAction<string>>;
  tempFrom: Date | null;
  setTempFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  tempTo: Date | null;
  setTempTo: React.Dispatch<React.SetStateAction<Date | null>>;
  tempExperienceAdults: number;
  setTempExperienceAdults: React.Dispatch<React.SetStateAction<number>>;
  tempExperienceChildren: number;
  setTempExperienceChildren: React.Dispatch<React.SetStateAction<number>>;
  tempExperienceInfants: number;
  setTempExperienceInfants: React.Dispatch<React.SetStateAction<number>>;
  onSearch: (data: any) => void;
}

const ExperienceSearchForm: React.FC<ExperienceSearchFormProps> = ({
  tempExperienceLocation,
  setTempExperienceLocation,
  tempFrom,
  setTempFrom,
  tempTo,
  setTempTo,
  tempExperienceAdults,
  setTempExperienceAdults,
  tempExperienceChildren,
  setTempExperienceChildren,
  tempExperienceInfants,
  setTempExperienceInfants,
  onSearch,
}) => {
  const { t, i18n } = useTranslation("guestsInput");
  const { location: contextLocation, setLocation: setContextLocation } = useExperienceLocationContext(); // Contexto de ubicación
  const { from: contextFrom, to: contextTo, setFrom, setTo } = useDatesContext(); // Contexto de fechas

  const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates" | "guests">("location");
  const [isLocationValid, setIsLocationValid] = useState(false);
  const [isDatesValid, setIsDatesValid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  // Define el locale dinámico basado en el idioma actual
  const currentLocale = i18n.language === "es" ? es : enUS;

  // Formatear fechas dinámicamente según el idioma (día y mes abreviado)
  const formatSelectedDates = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) {
      return t("addDate");
    }
    return `${format(startDate, "dd MMM", { locale: currentLocale })} - ${format(endDate, "dd MMM", {
      locale: currentLocale,
    })}`;
  };

  const renderInputLocation = () => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        fieldNameShow === "location" ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow("location")}
      >
        <span className="text-neutral-400">{t("location")}</span>
        <span>{tempExperienceLocation || t("addLocation")}</span>
      </button>
      {fieldNameShow === "location" && (
        <LocationInputExperiences
          headingText={t("whereTo")}
          tempExperienceLocation={tempExperienceLocation}
          setTempExperienceLocation={setTempExperienceLocation}
          setIsLocationValid={setIsLocationValid}
        />
      )}
    </div>
  );

  const renderInputDates = () => (
    <div
      className={`w-full bg-white dark:bg-neutral-800 ${
        fieldNameShow === "dates" ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
      }`}
    >
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow("dates")}
      >
        <span className="text-neutral-400">{t("when")}</span>
        <span>{formatSelectedDates(tempFrom, tempTo)}</span>
      </button>
      {fieldNameShow === "dates" && (
        <ExperienceDatesRangeInput
          onChange={(start, end) => {
            setTempFrom(start);
            setTempTo(end);
            setIsDatesValid(!!start && !!end);
          }}
          tempStartDate={tempFrom}
          tempEndDate={tempTo}
          setTempStartDate={setTempFrom}
          setTempEndDate={setTempTo}
          setIsDatesValid={setIsDatesValid}
        />
      )}
    </div>
  );

  const renderInputGuests = () => {
    const totalGuests = tempExperienceAdults + tempExperienceChildren;
    const guestText = totalGuests > 0 ? `${totalGuests} ${t("guests")}` : t("addGuests");

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          fieldNameShow === "guests" ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        <button
          className="w-full flex justify-between text-sm font-medium p-4"
          onClick={() => setFieldNameShow("guests")}
        >
          <span className="text-neutral-400">{t("who")}</span>
          <span>{guestText}</span>
        </button>
        {fieldNameShow === "guests" && (
          <ExperienceGuestsInput
            tempAdults={tempExperienceAdults}
            tempChildren={tempExperienceChildren}
            tempInfants={tempExperienceInfants}
            setTempAdults={setTempExperienceAdults}
            setTempChildren={setTempExperienceChildren}
            setTempInfants={setTempExperienceInfants}
          />
        )}
      </div>
    );
  };

  const handleSearch = () => {
    if (!isLocationValid || !isDatesValid) {
      setShowWarning(true); // Mostrar la advertencia si la ubicación o las fechas no son válidas
    } else {
      setShowWarning(false);
      onSearch({
        location: tempExperienceLocation,
        from: tempFrom,
        to: tempTo,
        adults: tempExperienceAdults,
        children: tempExperienceChildren,
        infants: tempExperienceInfants,
      });
    }
  };

  return (
    <div className="w-full space-y-5">
      {renderInputLocation()}
      {renderInputDates()}
      {renderInputGuests()}
    </div>
  );
};

export default ExperienceSearchForm;
