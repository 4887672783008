import React, { FC } from "react";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth2";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import { enUS, es } from "date-fns/locale";

// Registra las localizaciones disponibles
registerLocale("en", enUS);
registerLocale("es", es);

export interface ExperienceDatesRangeInputProps {
  className?: string;
  onChange?: (startDate: Date | null, endDate: Date | null) => void;
  setIsDatesValid: (isValid: boolean) => void;
  tempStartDate: Date | null;
  tempEndDate: Date | null;
  setTempStartDate: (date: Date | null) => void;
  setTempEndDate: (date: Date | null) => void;
}

const ExperienceDatesRangeInput: FC<ExperienceDatesRangeInputProps> = ({
  className = "",
  onChange,
  setIsDatesValid,
  tempStartDate,
  tempEndDate,
  setTempStartDate,
  setTempEndDate,
}) => {
  const { t, i18n } = useTranslation("stayDatesRangeInput");
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Establecemos la hora a 00:00:00 para evitar comparación con horas

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    // Validaciones de fechas: no permitir fechas pasadas
    if (start && start < today) {
      setTempStartDate(null);
      setIsDatesValid(false);
      return;
    }
    if (end && end < today) {
      setTempEndDate(null);
      setIsDatesValid(false);
      return;
    }

    // Actualizar fechas temporales solo si ambas son válidas
    setTempStartDate(start);
    setTempEndDate(end);
    setIsDatesValid(!!start && !!end);

    // Notificar cambios al padre
    if (onChange) {
      onChange(start, end);
    }
  };

  const dayClassName = (date: Date) => {
    const dateTime = date.getTime();
    const startTime = tempStartDate ? tempStartDate.getTime() : 0;
    const endTime = tempEndDate ? tempEndDate.getTime() : startTime;

    if (dateTime >= startTime && dateTime <= endTime) {
      return "bg-gray-300";
    }
    if (dateTime === startTime || dateTime === endTime) {
      return "bg-blue-500 text-white";
    }

    return "";
  };

  const currentLocale = i18n.language; // Obtener el idioma actual

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">{t("whenIsYourTrip")}</span>
      </div>
      <div className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className}`}>
        <DatePicker
          selected={tempStartDate}
          onChange={onChangeDate}
          startDate={tempStartDate}
          endDate={tempEndDate}
          selectsRange
          minDate={today}
          monthsShown={2}
          locale={currentLocale} // Idioma dinámico
          showPopperArrow={false}
          inline
          renderCustomHeader={(props) => <DatePickerCustomHeaderTwoMonth {...props} />}
          renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day} date={date} />}
          dayClassName={dayClassName}
        />
      </div>
    </div>
  );
};

export default ExperienceDatesRangeInput;
