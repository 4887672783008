import React, { FC, Fragment } from "react";
import DatePicker from "react-datepicker";
import { Transition } from "@headlessui/react";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth2 from "components/DatePickerCustomHeaderTwoMonth2";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useTranslation } from "react-i18next";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";

export interface ExperiencesDateSingleInputProps {
  className?: string;
  fieldClassName?: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void; // Nuevo prop para cerrar modal
  onDatesChange: (startDate: Date | null, endDate: Date | null) => void;
}

const ExperiencesDateSingleInput: FC<ExperiencesDateSingleInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  isOpen,
  onOpen,
  onClose, // Recibimos onClose
  onDatesChange,
}) => {
  const { t } = useTranslation("experiencesDateSingleInput");
  const {
    from: startDate,
    to: endDate,
    setFrom: setStartDate,
    setTo: setEndDate,
  } = useExperienceDatesContext();
  const refContainer = React.createRef<HTMLDivElement>();

  useOutsideAlerter(refContainer, onClose);

  const handleChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onDatesChange(start, end);

    // Si ambas fechas están seleccionadas, cerramos el modal
    if (start && end) {
      onClose();
    }
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const renderInput = () => (
    <div className="flex-grow text-left">
      <span className="block xl:text-base font-semibold">
        {startDate
          ? startDate.toLocaleDateString("es-ES", { month: "short", day: "2-digit" })
          : t("addDates")}
        {endDate
          ? " - " + endDate.toLocaleDateString("es-ES", { month: "short", day: "2-digit" })
          : ""}
      </span>
      <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
        {t("dates")}
      </span>
    </div>
  );

  return (
    <div ref={refContainer} className={`ExperiencesDateSingleInput relative flex ${className}`}>
      <div
        className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 cursor-pointer focus:outline-none ${
          isOpen ? "nc-hero-field-focused--2" : ""
        }`}
        onClick={onOpen}
      >
        {renderInput()}
        {startDate && isOpen && <ClearDataButton onClick={handleClearDates} />}
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
          <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
            <DatePicker
              selected={startDate}
              onChange={handleChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              minDate={new Date()}
              monthsShown={2}
              showPopperArrow={false}
              inline
              renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth2 {...p} />}
              renderDayContents={(day, date) => (
                <DatePickerCustomDay dayOfMonth={day} date={date} />
              )}
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ExperiencesDateSingleInput;
