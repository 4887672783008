import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import convertNumbThousand from "utils/convertNumbThousand";
import { useTranslation } from "react-i18next";
import { useExperiencesFilterContext } from "contexts/ExperiencesFilterContext";
import ExperiencesMobileFilterMenu from "./ExperiencesMobileFilterMenu";
import ExperiencesMoreFiltersMobile from "./ExperiencesMoreFiltersMobile";
import ExperiencesSortByMobile from "./ExperiencesSortByMobile";

const ExpTabFilters: React.FC<{
  onMapClick: () => void; // Función para abrir/cerrar el mapa
  isMapOpen: boolean; // Estado global del mapa
}> = ({ onMapClick, isMapOpen }) => {
  const { t } = useTranslation("tabFilters");

  // Utiliza el contexto de filtros
  const {
    priceRange,
    setPriceRange,
    selectedTypes,
    setSelectedTypes,
    selectedTimeOfDay,
    setSelectedTimeOfDay,
  } = useExperiencesFilterContext();

  // Estados temporales para filtros
  const [tempSelectedPlaceTypes, setTempSelectedPlaceTypes] = useState<string[]>(selectedTypes);
  const [tempPriceRange, setTempPriceRange] = useState(priceRange);
  //const [tempSelectedPuntuacion, setTempSelectedPuntuacion] = useState<string[]>(selectedPuntuacion);
  const { sortBy, setSortBy } = useExperiencesFilterContext();
  const [tempSortBy, setTempSortBy] = useState(sortBy || "featured");

  const [isSortByMobileOpen, setIsSortByMobileOpen] = useState(false);

  // Estados para manejo de modales
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false); // Modal de filtros (escritorio)
  const [isMoreFiltersMobileOpen, setIsMoreFiltersMobileOpen] = useState(false); // Modal de filtros (móvil)

   // Funciones para abrir/cerrar modales
   const openFiltersMobile = () => setIsMoreFiltersMobileOpen(true);
   const closeFiltersMobile = () => setIsMoreFiltersMobileOpen(false);
 
   const openSortByMobile = () => setIsSortByMobileOpen(true);
   const closeSortByMobile = () => setIsSortByMobileOpen(false);

  const sortOptions = [
    { value: "featured", label: t("featured") },
    { value: "highestPrice", label: t("highestPrice") },
    { value: "lowestPrice", label: t("lowestPrice") },
    { value: "reviewStart", label: t("bestValue") },
    { value: "reviewCount", label: t("topRated") },
  ];

  const handleApplySort = () => {
  };

  useEffect(() => {
    setTempSelectedPlaceTypes(selectedTypes);
  }, [selectedTypes]);

  // Maneja el cambio de tipos de lugar
  const handleTypeChange = (type: string) => {
    setTempSelectedPlaceTypes((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((t) => t !== type)
        : [...prevTypes, type]
    );
  };


  const renderTabsTypeOfPlace = () => (
    <div className="flex flex-wrap gap-4 w-full">
      {[
        { name: "foodAndDrinks", labelKey: "foodAndDrinks", icon: "🥣" },
        { name: "artAndCulture", labelKey: "artAndCulture", icon: "🎨" },
        { name: "natureAndOutdoors", labelKey: "natureAndOutdoors", icon: "🌳" },
        { name: "sports", labelKey: "sports", icon: "⚽" },
      ].map((item) => (
        <button
          key={item.name}
          onClick={() => handleTypeChange(item.name)}
          className="flex items-center space-x-2 px-4 py-2 text-sm rounded-full border focus:outline-none border-neutral-300 hover:border-neutral-400"
        >
          {/* Ícono */}
          <span className="text-sm">{item.icon}</span>
          {/* Texto */}
          <span>{t(item.labelKey)}</span>
        </button>
      ))}
    </div>
  );


  const renderTabsPriceRange = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-400 bg-primary-50 text-primary-400 focus:outline-none">
            <span>{`$${convertNumbThousand(tempPriceRange[0])} - $${convertNumbThousand(tempPriceRange[1])}`}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-8">
                  <span className="font-medium">{t("pricePerDay")}</span>
                  <Slider
                    range
                    min={0}
                    max={2000}
                    value={tempPriceRange}
                    allowCross={false}
                    onChange={(value) => setTempPriceRange(value as number[])}
                  />
                  <div className="flex justify-between space-x-5">
                    <div className="w-1/2">
                      <label htmlFor="minPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("minPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="minPrice"
                          id="minPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={tempPriceRange[0]}
                          min={0}
                          max={tempPriceRange[1]}
                          onChange={(e) => setTempPriceRange([Number(e.target.value), tempPriceRange[1]])}
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="maxPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("maxPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="maxPrice"
                          id="maxPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={tempPriceRange[1]}
                          min={tempPriceRange[0]}
                          max={2000}
                          onChange={(e) => setTempPriceRange([tempPriceRange[0], Number(e.target.value)])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempPriceRange([0, 2000])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      setPriceRange(tempPriceRange);
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  const renderSortDropdown = () => {
    const getButtonClasses = (isSelected: boolean) =>
      `text-left px-4 py-2 hover:bg-neutral-100 rounded flex items-center ${isSelected ? "font-semibold text-primary-500" : "text-neutral-700"
      }`;

    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
              aria-haspopup="menu"
              aria-expanded={open}
            >
              <span>{sortBy ? sortOptions.find((option) => option.value === sortBy)?.label : t("sortBy")}</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-10 max-w-max px-4 mt-3 left-0 sm:px-0"
                role="menu"
              >
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                  <ul className="relative flex flex-col px-5 py-2 space-y-2">
                    {sortOptions.map((option) => (
                      <li key={option.value}>
                        <button
                          className={getButtonClasses(tempSortBy === option.value)}
                          onClick={() => setTempSortBy(option.value)} // Actualiza el estado temporal
                          role="menuitem"
                        >
                          <i className={`mr-2 ${getIconForOption(option.value)}`} /> {/* Ícono para cada opción */}
                          {option.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="p-5 bg-neutral-50 flex items-center justify-between space-x-4">
                    <ButtonThird
                      onClick={() => {
                        setTempSortBy(""); // Limpiar el orden
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("clear")}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplySort(); // Aplica el orden
                        close(); // Cierra el popover
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("apply")}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // Función para retornar el ícono según la opción seleccionada
  const getIconForOption = (value: string) => {
    switch (value) {
      case "lowestPrice":
        return "las la-sort-amount-up"; // Ícono de precio más bajo
      case "highestPrice":
        return "las la-sort-amount-down"; // Ícono de precio más alto
      case "reviewStart":
        return "las la-thumbs-up"; // Ícono de reviewStart
      case "reviewCount":
        return "las la-star"; // Ícono de reviewCount
      case "featured":
        return "las la-certificate"; // Ícono de Destacado (certificado)
      default:
        return ""; // Sin ícono
    }
  };

  /*const renderTabsPuntuacion = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
          >
            <span>{t("Puntuación")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-auto max-w-md px-4 mt-3 left-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {puntuacionOptions.map((item) => (
                    <div key={item.name} className="flex items-center space-x-3">
                      <Checkbox
                        name={item.name}
                        checked={tempSelectedPuntuacion.includes(item.name)}
                        onChange={() => handlePuntuacionChange(item.name)}
                        className="mr-3"
                      />
                      <span>{item.label}</span>
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 flex items-center justify-between space-x-4"> 
                  <ButtonThird
                    onClick={() => setTempSelectedPuntuacion([])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      handleApplyPuntuacion();
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  ); */

  // Manejar la aplicación de filtros
  const handleApplyFilters = (types: string[], timeOfDay: string[], range: number[]) => {
    setSelectedTypes(types);
    setSelectedTimeOfDay(timeOfDay);
    setPriceRange(range);
    closeFiltersMobile(); // Cierra el modal después de aplicar
  };

  // Manejar la limpieza de filtros
  const handleClearFilters = () => {
    setSelectedTypes([]);
    setSelectedTimeOfDay([]);
    setPriceRange([0, 2000]);
  };

  return (
    <div>
      {/* Filtros para escritorio */}
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfPlace()}
        {renderTabsPriceRange()}
        {renderSortDropdown()}
      </div>

      {/* Barra de menú móvil */}
      <div className="lg:hidden px-6">
        <ExperiencesMobileFilterMenu
          onFiltersExpClick={openFiltersMobile}
          onSortExpClick={openSortByMobile} // Lógica para abrir el modal
          onMapExpClick={onMapClick}
          isMapExpOpen={isMapOpen}
        />
      </div>

      {/* Modal de filtros móviles */}
      {isMoreFiltersMobileOpen && ( // Usa el estado específico para el modal móvil
        <ExperiencesMoreFiltersMobile
        isOpen={isMoreFiltersMobileOpen}
        closeModal={closeFiltersMobile}
        selectedTypes={selectedTypes}
        selectedTimeOfDay={selectedTimeOfDay}
        priceRange={priceRange}
        onApply={handleApplyFilters}
        onClear={handleClearFilters}
      />
      )}
      {/* Modal de "Sort By" para móvil */}
      {isSortByMobileOpen && (
        <ExperiencesSortByMobile
          isOpen={isSortByMobileOpen}
          closeModal={closeSortByMobile}
          selectedSortBy={sortBy} // Pasa el valor actual desde el contexto
          onApply={(selectedSortBy) => {
            setSortBy(selectedSortBy); // Actualiza el contexto con el nuevo valor
            closeSortByMobile(); // Cierra el modal móvil
          }}
        />
      )}
    </div>
  );
};

export default ExpTabFilters;