import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";

interface MoreFiltersProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  selectedAmenities: string[];
  selectedFacilities: string[];
  onApply: (amenities: string[], facilities: string[]) => void;
  onClear: () => void;
}

const MoreFilters: React.FC<MoreFiltersProps> = ({
  isOpen,
  openModal,
  closeModal,
  selectedAmenities,
  selectedFacilities,
  onApply,
  onClear,
}) => {
  const { t } = useTranslation("moreFilters");
  const { t: tAmenities } = useTranslation("amenities");
  const { t: tFacilities } = useTranslation("facilities");
  const [tempAmenities, setTempAmenities] = useState<string[]>(selectedAmenities);
  const [tempFacilities, setTempFacilities] = useState<string[]>(selectedFacilities);

  useEffect(() => {
    setTempAmenities(selectedAmenities);
    setTempFacilities(selectedFacilities);
  }, [selectedAmenities, selectedFacilities]);

  const handleAmenityChange = (amenity: string) => {
    setTempAmenities((prev) =>
      prev.includes(amenity) ? prev.filter((a) => a !== amenity) : [...prev, amenity]
    );
  };

  const handleFacilityChange = (facility: string) => {
    setTempFacilities((prev) =>
      prev.includes(facility) ? prev.filter((f) => f !== facility) : [...prev, facility]
    );
  };

  const handleApply = () => {
    onApply(tempAmenities, tempFacilities);
  };

  const handleClear = () => {
    setTempAmenities([]);
    setTempFacilities([]);
    onClear();
  };

  const renderMoreFilterItem = (data: { name: string; labelKey: string }[], type: string) => {
    return (
      <div className="grid grid-cols-2 gap-8">
        {data.map((item) => (
          <Checkbox
            key={item.name}
            name={item.name}
            label={type === "amenities" ? tAmenities(item.labelKey) : tFacilities(item.labelKey)}
            checked={
              type === "amenities"
                ? tempAmenities.includes(item.name)
                : tempFacilities.includes(item.name)
            }
            onChange={() =>
              type === "amenities"
                ? handleAmenityChange(item.name)
                : handleFacilityChange(item.name)
            }
          />
        ))}
      </div>
    );
  };

  const facilities = [
    { name: "parking", labelKey: "parking" },
    { name: "pets_allowed", labelKey: "pets_allowed" },
    { name: "non_smoking_rooms", labelKey: "non_smoking_rooms" },
    { name: "pool", labelKey: "pool" },
    { name: "free_wifi", labelKey: "free_wifi" },
    { name: "terrace", labelKey: "terrace" },
    { name: "kitchen", labelKey: "kitchen" },
    { name: "private_bathrooms", labelKey: "private_bathrooms" },
  ];

  const amenities = [
    { name: "coffeMaker", labelKey: "coffeMaker" },
    { name: "oven", labelKey: "oven" },
    { name: "microwave", labelKey: "microwave" },
    { name: "hairDryer", labelKey: "hairDryer" },
    { name: "washingMachine", labelKey: "washingMachine" },
    { name: "jacuzzi", labelKey: "jacuzzi" },
  ];

  const countFiltersApplied = () => tempAmenities.length + tempFacilities.length;

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${
              open ? "!border-primary-500" : ""
            }`}
            onClick={openModal}
          >
            <span>{t("title")} ({countFiltersApplied()})</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
              <div className="min-h-screen text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
                </Transition.Child>

                <span className="inline-block h-screen align-middle" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                    <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {t("title")}
                      </Dialog.Title>
                      <span className="absolute left-3 top-3">
                        <ButtonClose onClick={closeModal} />
                      </span>
                    </div>

                    <div className="flex-grow overflow-y-auto">
                      <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                        <div className="py-7">
                          <h3 className="text-xl font-medium">{t("amenities")}</h3>
                          <div className="mt-6 relative">
                            {renderMoreFilterItem(amenities, "amenities")}
                          </div>
                        </div>
                        <div className="py-7">
                          <h3 className="text-xl font-medium">{t("facilities")}</h3>
                          <div className="mt-6 relative">
                            {renderMoreFilterItem(facilities, "facilities")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                      <ButtonThird onClick={handleClear} sizeClass="px-4 py-2 sm:px-5 text-neutral-700">
                        {t("clear")}
                      </ButtonThird>
                      <ButtonPrimary onClick={handleApply} sizeClass="px-4 py-2 sm:px-5">
                        {t("apply")}
                      </ButtonPrimary>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default MoreFilters;
