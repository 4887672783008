import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

interface MoreFiltersMobileProps {
  selectedAmenities: string[];
  selectedFacilities: string[];
  onApply: (amenities: string[], facilities: string[]) => void;
  onClear: () => void;
  isOpen: boolean;
  closeModal: () => void;
}

const MoreFiltersMobile: React.FC<MoreFiltersMobileProps> = ({
  selectedAmenities = [],
  selectedFacilities = [],
  onApply,
  onClear,
  isOpen,
  closeModal,
}) => {
  const { t: tMobileFilters } = useTranslation("mobileFilters");
  const { t: tAmenities } = useTranslation("amenities");
  const { t: tFacilities } = useTranslation("facilities");
  const { t: tTabFilters } = useTranslation("tabFilters");
  const { t: tbedsAndBathrooms } = useTranslation("bedsAndBathroomsFilters");

  const [tempAmenities, setTempAmenities] = useState<string[]>(selectedAmenities);
  const [tempFacilities, setTempFacilities] = useState<string[]>(selectedFacilities);
  const [fieldNameShow, setFieldNameShow] = useState<
    "typeOfPlace" | "priceRange" | "puntuacion" | "bedsAndBathrooms" | "amenities" | "facilities" | null
  >(null);

  const [tempSelectedPlaceTypes, setTempSelectedPlaceTypes] = useState<string[]>([]);
  const [tempPriceRange, setTempPriceRange] = useState<number[]>([0, 2000]);
  const [tempSelectedPuntuacion, setTempSelectedPuntuacion] = useState<string[]>([]);
  const [bedroomsCount, setBedroomsCount] = useState<number>(0);
  const [bathroomsCount, setBathroomsCount] = useState<number>(0);

  useEffect(() => {
    setTempAmenities(selectedAmenities);
    setTempFacilities(selectedFacilities);
  }, [selectedAmenities, selectedFacilities]);

  const handleAmenityChange = (amenity: string) => {
    setTempAmenities((prev) =>
      prev.includes(amenity) ? prev.filter((a) => a !== amenity) : [...prev, amenity]
    );
  };

  const handleFacilityChange = (facility: string) => {
    setTempFacilities((prev) =>
      prev.includes(facility) ? prev.filter((f) => f !== facility) : [...prev, facility]
    );
  };

  const handleApply = () => {
    onApply(tempAmenities, tempFacilities);
    closeModal();
  };

  const handleClear = () => {
    setTempAmenities([]);
    setTempFacilities([]);
    setTempSelectedPlaceTypes([]);
    setTempPriceRange([0, 2000]);
    setTempSelectedPuntuacion([]);
    setBedroomsCount(0);
    setBathroomsCount(0);
    onClear();
  };

  const typeOfPlaces = [
    { name: "house", descriptionKey: "privateRoomDescription" },
    { name: "villa", descriptionKey: "hotelRoomDescription" },
    { name: "hotel", descriptionKey: "sharedRoomDescription" },
    { name: "inn", descriptionKey: "hotelRoomDescription" },
    { name: "motel", descriptionKey: "sharedRoomDescription" },
    { name: "boat", descriptionKey: "sharedRoomDescription" },
    { name: "apartment", descriptionKey: "entirePlaceDescription" },

  ];

  const puntuacionOptions = [
    { name: "genial", label: "Genial" },
    { name: "muyBien", label: "Muy Bien" },
    { name: "bien", label: "Bien" },
    { name: "agradable", label: "Agradable" },
  ];

  const renderSection = (title: string, type: string, content: React.ReactNode) => (
    <div className={`w-full ${fieldNameShow === type ? "rounded-2xl shadow-lg" : "rounded-xl shadow-sm"}`}>
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow(fieldNameShow === type ? null : (type as any))}
      >
        <span>{tMobileFilters(title)}</span> {/* Traducción dinámica del título */}
      </button>
      {fieldNameShow === type && <div className="px-4 py-2">{content}</div>}
    </div>
  );

  const getIconForType = (type: string) => {
    switch (type) {

      case "house":
        return "🏠";
      case "villa":
        return "🏡";
      case "hotel":
        return "🏨";
      case "inn":
        return "🏩";
      case "motel":
        return "🏬";
      case "boat":
        return "⛵";
      case "apartment":
        return "🏢";
      default:
        return "🏕️";
    }
  };

  const renderTypeOfPlace = () => (
    <div className="flex flex-wrap gap-4 w-full">
      {typeOfPlaces.map((item) => {
        const isActive = tempSelectedPlaceTypes.includes(item.name);
        return (
          <button
            key={item.name}
            onClick={() => {
              setTempSelectedPlaceTypes((prevTypes) =>
                prevTypes.includes(item.name)
                  ? prevTypes.filter((t) => t !== item.name)
                  : [...prevTypes, item.name]
              );
            }}
            className={`flex items-center space-x-2 px-4 py-2 text-sm rounded-full border focus:outline-none ${
              isActive
                ? "border-primary-500 bg-primary-100 text-primary-500"
                : "border-neutral-300 hover:border-neutral-400"
            }`}
          >
            {/* Ícono */}
            <span className="text-sm">{getIconForType(item.name)}</span>
            {/* Texto */}
            <span>{tTabFilters(item.name)}</span>
            {/* Botón de eliminar (solo cuando está activo) */}
            {isActive && (
              <span
                className="ml-2 w-4 h-4 flex items-center justify-center rounded-full bg-primary-500 text-white text-xs font-bold"
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el clic en la "x" afecte el botón principal
                  setTempSelectedPlaceTypes((prevTypes) =>
                    prevTypes.filter((t) => t !== item.name)
                  );
                }}
              >
                ×
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
  
  const renderPriceRange = () => (
    <div className="space-y-8">
      <Slider
        range
        min={0}
        max={2000}
        value={tempPriceRange}
        allowCross={false}
        onChange={(value) => setTempPriceRange(value as number[])}
      />
      <div className="flex justify-between space-x-5">
        <div className="w-1/2">
          <label htmlFor="minPrice" className="block text-sm font-medium text-neutral-700">
            {tMobileFilters("minPrice")}
          </label>
          <input
            type="number"
            name="minPrice"
            id="minPrice"
            className="block w-full pl-3 pr-3 sm:text-sm border border-neutral-200 rounded-full"
            value={tempPriceRange[0]}
            min={0}
            max={tempPriceRange[1]}
            onChange={(e) =>
              setTempPriceRange([Number(e.target.value), tempPriceRange[1]])
            }
          />
        </div>
        <div className="w-1/2">
          <label htmlFor="maxPrice" className="block text-sm font-medium text-neutral-700">
            {tMobileFilters("maxPrice")}
          </label>
          <input
            type="number"
            name="maxPrice"
            id="maxPrice"
            className="block w-full pl-3 pr-3 sm:text-sm border border-neutral-200 rounded-full"
            value={tempPriceRange[1]}
            min={tempPriceRange[0]}
            max={2000}
            onChange={(e) =>
              setTempPriceRange([tempPriceRange[0], Number(e.target.value)])
            }
          />
        </div>
      </div>
    </div>
  );


  const renderPuntuacion = () => (
    <div className="space-y-5">
      {puntuacionOptions.map((item) => (
        <div key={item.name} className="flex items-center space-x-4">
          {/* Checkbox */}
          <Checkbox
            name={item.name}
            checked={tempSelectedPuntuacion.includes(item.name)}
            onChange={() => {
              setTempSelectedPuntuacion((prevPuntuacion) =>
                prevPuntuacion.includes(item.name)
                  ? prevPuntuacion.filter((p) => p !== item.name)
                  : [...prevPuntuacion, item.name]
              );
            }}
          />
          {/* Texto */}
          <span className="flex-grow">{tMobileFilters(item.label)}</span>
          {/* Ícono */}
        </div>
      ))}
    </div>
  );


  const renderBedsAndBathrooms = () => (
    <div className="space-y-6 px-5 py-6">
      {/* Bedrooms */}
      <div className="flex items-center justify-between">
        <span>{tbedsAndBathrooms("bedrooms")}</span>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setBedroomsCount((prev) => Math.max(0, prev - 1))}
            className="px-3 py-1 border rounded-full text-sm"
          >
            −
          </button>
          <span className="w-8 text-center">{bedroomsCount}</span>
          <button
            onClick={() => setBedroomsCount((prev) => prev + 1)}
            className="px-3 py-1 border rounded-full text-sm"
          >
            +
          </button>
        </div>
      </div>

      {/* Bathrooms */}
      <div className="flex items-center justify-between">
        <span>{tbedsAndBathrooms("bathrooms")}</span>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setBathroomsCount((prev) => Math.max(0, prev - 1))}
            className="px-3 py-1 border rounded-full text-sm"
          >
            −
          </button>
          <span className="w-8 text-center">{bathroomsCount}</span>
          <button
            onClick={() => setBathroomsCount((prev) => prev + 1)}
            className="px-3 py-1 border rounded-full text-sm"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );

  const amenities = [
    { name: "coffeMaker", labelKey: "coffeMaker" },
    { name: "oven", labelKey: "oven" },
    { name: "microwave", labelKey: "microwave" },
    { name: "hairDryer", labelKey: "hairDryer" },
    { name: "washingMachine", labelKey: "washingMachine" },
    { name: "jacuzzi", labelKey: "jacuzzi" },
  ];

  const facilities = [
    { name: "parking", labelKey: "parking" },
    { name: "pets_allowed", labelKey: "pets_allowed" },
    { name: "non_smoking_rooms", labelKey: "non_smoking_rooms" },
    { name: "pool", labelKey: "pool" },
    { name: "free_wifi", labelKey: "free_wifi" },
    { name: "terrace", labelKey: "terrace" },
  ];

  const getIconClassForItem = (itemName: string): string => {
    const iconsMap: { [key: string]: string } = {
      coffeMaker: "la-coffee",
      oven: "la-fire",
      microwave: "la-tablet",
      hairDryer: "la-wind",
      washingMachine: "la-tshirt",
      jacuzzi: "la-hot-tub",
      parking: "la-parking",
      pets_allowed: "la-dog",
      non_smoking_rooms: "la-smoking-ban",
      pool: "la-swimmer",
      free_wifi: "la-wifi",
      terrace: "la-building",
    };
  
    return iconsMap[itemName] || "la-question"; // Usa un ícono predeterminado si no encuentra coincidencia
  };  

  const renderMoreFilterItem = (data: { name: string; labelKey: string }[], type: string) => (
    <div className="flex flex-wrap gap-4 w-full">
      {data.map((item) => {
        const isActive =
          type === "amenities"
            ? tempAmenities.includes(item.name)
            : tempFacilities.includes(item.name);
  
        return (
          <button
            key={item.name}
            onClick={() =>
              type === "amenities"
                ? handleAmenityChange(item.name)
                : handleFacilityChange(item.name)
            }
            className={`flex items-center space-x-2 px-4 py-2 text-sm rounded-full border focus:outline-none ${
              isActive
                ? "border-primary-500 bg-primary-100 text-primary-500"
                : "border-neutral-300 hover:border-neutral-400"
            }`}
          >
            {/* Ícono */}
            <span className={`la ${getIconClassForItem(item.name)} text-base`} />
            {/* Texto del botón */}
            <span>
              {type === "amenities" ? tAmenities(item.labelKey) : tFacilities(item.labelKey)}
            </span>
            {/* Botón de eliminar (solo cuando está activo) */}
            {isActive && (
              <span
                className="ml-2 w-4 h-4 flex items-center justify-center rounded-full bg-primary-500 text-white text-xs font-bold"
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el clic en la "x" afecte el botón principal
                  type === "amenities"
                    ? handleAmenityChange(item.name)
                    : handleFacilityChange(item.name);
                }}
              >
                ×
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
  

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-[calc(100vh-50px)]"
            >
              {/* Header */}
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title as="h3" className="text-lg font-medium">
                  {tMobileFilters("title")}
                </Dialog.Title>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={closeModal} />
                </span>
              </div>

              {/* Contenido con scroll vertical */}
              <div className="flex-grow overflow-y-auto px-6 space-y-4">
                {renderSection("typeOfPlace", "typeOfPlace", renderTypeOfPlace())}
                {renderSection("priceRange", "priceRange", renderPriceRange())}
                {renderSection("puntuacion", "puntuacion", renderPuntuacion())}
                {renderSection("bedsAndBathrooms", "bedsAndBathrooms", renderBedsAndBathrooms())}
                {renderSection("amenities", "amenities", renderMoreFilterItem(amenities, "amenities"))}
                {renderSection("facilities", "facilities", renderMoreFilterItem(facilities, "facilities"))}
              </div>

              {/* Footer */}
              <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                <ButtonThird onClick={handleClear}>{tMobileFilters("clear")}</ButtonThird>
                <ButtonPrimary onClick={handleApply}>{tMobileFilters("apply")}</ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );

};

export default MoreFiltersMobile;
