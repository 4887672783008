import React, { createContext, useContext, useEffect, useState } from "react";
import { openDB } from "idb";

interface ExperienceDatesContextType {
  from: Date | null;
  to: Date | null;
  setFrom: (date: Date | null) => void;
  setTo: (date: Date | null) => void;
}

const DB_NAME = "ExperienceDatesDB";
const STORE_NAME = "experienceDates";

const ExperienceDatesContext = createContext<ExperienceDatesContextType | undefined>(undefined);

export const ExperienceDatesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const loadDatesFromDB = async () => {
    const db = await initDB();
    const savedDates = await db.get(STORE_NAME, 1);
    return savedDates || { from: null, to: null };
  };

  const saveDatesToDB = async (dates: { from: Date | null; to: Date | null }) => {
    const db = await initDB();
    await db.put(STORE_NAME, {
      id: 1,
      from: dates.from ? dates.from.toISOString() : null,
      to: dates.to ? dates.to.toISOString() : null,
    });
  };

  useEffect(() => {
    const fetchDates = async () => {
      const savedDates = await loadDatesFromDB();
      setFrom(savedDates.from ? new Date(savedDates.from) : null); // Convertir string a Date
      setTo(savedDates.to ? new Date(savedDates.to) : null); // Convertir string a Date
      setIsLoading(false);
    };
    fetchDates();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveDatesToDB({ from, to });
    }
  }, [from, to, isLoading]);

  if (isLoading) {
    return null; // Renderiza un spinner o placeholder mientras se cargan los datos
  }

  return (
    <ExperienceDatesContext.Provider value={{ from, to, setFrom, setTo }}>
      {children}
    </ExperienceDatesContext.Provider>
  );
};

export const useExperienceDatesContext = () => {
  const context = useContext(ExperienceDatesContext);
  if (!context) {
    throw new Error("useExperienceDatesContext must be used within an ExperienceDatesProvider");
  }
  return context;
};
