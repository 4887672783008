import React, { createContext, useContext, useEffect, useState } from "react";
import { openDB } from "idb";

interface FiltersContextProps {
  priceRange: number[];
  setPriceRange: (value: number[]) => void;
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
  bedroomsCount: number;
  setBedroomsCount: (count: number) => void;
  bathroomsCount: number;
  setBathroomsCount: (count: number) => void;
  selectedAmenities: string[];
  setSelectedAmenities: (amenities: string[]) => void;
  selectedFacilities: string[];
  setSelectedFacilities: (facilities: string[]) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
  selectedPuntuacion: string[];
  setSelectedPuntuacion: (puntuacion: string[]) => void;
}

const DB_NAME = "FiltersDB";
const STORE_NAME = "filters";

const FiltersContext = createContext<FiltersContextProps | undefined>(undefined);

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<FiltersContextProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const loadFiltersFromDB = async () => {
    const db = await initDB();
    const savedFilters = await db.get(STORE_NAME, 1);
    return (
      savedFilters || {
        priceRange: [0, 1000],
        selectedTypes: [],
        bedroomsCount: 0,
        bathroomsCount: 0,
        selectedAmenities: [],
        selectedFacilities: [],
        sortBy: "featured",
        selectedPuntuacion: [],
      }
    );
  };

  const saveFiltersToDB = async (newFilters: any) => {
    const db = await initDB();
    await db.put(STORE_NAME, { id: 1, ...newFilters });
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const storedFilters = await loadFiltersFromDB();
      setFilters(storedFilters);
      setIsLoading(false);
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    if (filters) {
      saveFiltersToDB(filters);
    }
  }, [filters]);

  if (isLoading) {
    return null; // Puedes renderizar un spinner mientras se cargan los datos
  }

  const setPriceRange = (value: number[]) => {
    setFilters((prev) => ({ ...prev!, priceRange: value }));
  };

  const setSelectedTypes = (types: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedTypes: types }));
  };

  const setBedroomsCount = (count: number) => {
    setFilters((prev) => ({ ...prev!, bedroomsCount: count }));
  };

  const setBathroomsCount = (count: number) => {
    setFilters((prev) => ({ ...prev!, bathroomsCount: count }));
  };

  const setSelectedAmenities = (amenities: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedAmenities: amenities }));
  };

  const setSelectedFacilities = (facilities: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedFacilities: facilities }));
  };

  const setSortBy = (value: string) => {
    setFilters((prev) => ({ ...prev!, sortBy: value }));
  };

  const setSelectedPuntuacion = (puntuacion: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedPuntuacion: puntuacion }));
  };

  return (
    <FiltersContext.Provider
      value={{
        priceRange: filters!.priceRange,
        setPriceRange,
        selectedTypes: filters!.selectedTypes,
        setSelectedTypes,
        bedroomsCount: filters!.bedroomsCount,
        setBedroomsCount,
        bathroomsCount: filters!.bathroomsCount,
        setBathroomsCount,
        selectedAmenities: filters!.selectedAmenities,
        setSelectedAmenities,
        selectedFacilities: filters!.selectedFacilities,
        setSelectedFacilities,
        sortBy: filters!.sortBy,
        setSortBy,
        selectedPuntuacion: filters!.selectedPuntuacion,
        setSelectedPuntuacion,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error("useFiltersContext must be used within a FiltersProvider");
  }
  return context;
};