import React, { FC, ReactNode } from "react";

export interface CheckboxProps {
  label?: string | ReactNode; // Permitir texto o JSX
  subLabel?: string;
  className?: string;
  name: string;
  icon?: string; // Icono opcional
  checked?: boolean; // Cambiar defaultChecked a checked
  onChange?: (checked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  checked, // Cambiar defaultChecked a checked
  onChange,
}) => {
  return (
    <div className={`flex text-sm sm:text-base items-center ${className}`}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
        checked={checked} // Usar checked en lugar de defaultChecked
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {label && (
        <label
          htmlFor={name}
          className="ml-3.5 flex flex-col flex-1 justify-center"
        >
          {typeof label === "string" ? (
            <span className="text-neutral-900 dark:text-neutral-100">{label}</span>
          ) : (
            label // Renderizar JSX directamente si no es string
          )}
          {subLabel && (
            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
