import React, { useState, Fragment, useTransition } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Importar useLocation
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import Logo from "shared/Logo/LogoMobileSearch";
import StaySearchForm from "./(stay-search-form)/StaySearchForm"; // Formulario para Stay
import ExperienceSearchForm from "./experiences-search-form/ExperiencesSearchForm"; // Formulario para Experiences
import { useLocationContext } from "contexts/LocationContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext"; // Contexto de ubicación para experiencias
import { useDatesContext } from "contexts/DatesContext"; // Contexto de fechas para Stay
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext"; // Contexto de fechas para Experiencias
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";

const HeroSearchForm2Mobile = () => {
  const { t, i18n } = useTranslation("heroSearchForm2Mobile");
  const navigate = useNavigate();
  const currentRoute = useLocation(); // Obtener la ruta actual
  const [showModal, setShowModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<"stay" | "experience">("stay");
  const [isPending, startTransition] = useTransition(); // Usamos startTransition

  // Contextos para Stay 
  const { location: stayLocation, setLocation: setStayLocation } = useLocationContext();
  const { from: stayFrom, to: stayTo, setFrom: setStayFrom, setTo: setStayTo } = useDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();

  // Contexto específico de Experiencias
  const { location: experienceLocation, setLocation: setExperienceLocation } = useExperienceLocationContext();
  const { from: experienceFrom, to: experienceTo, setFrom: setExperienceFrom, setTo: setExperienceTo } = useExperienceDatesContext();
  const { adults: experienceAdults, children: experienceChildren, infants: experienceInfants, setAdults: setExperienceAdults, setChildren: setExperienceChildren, setInfants: setExperienceInfants } = useExperienceGuestsContext(); // Contexto de huéspedes para Experiencias

  // Estados temporales para Stay
  const [tempStayLocation, setTempStayLocation] = useState(stayLocation || "");
  const [tempStayFrom, setTempStayFrom] = useState<Date | null>(stayFrom);
  const [tempStayTo, setTempStayTo] = useState<Date | null>(stayTo);
  const [tempStayAdults, setTempStayAdults] = useState(adults);
  const [tempStayChildren, setTempStayChildren] = useState(children);
  const [tempStayInfants, setTempStayInfants] = useState(infants);

  // Estados temporales para Experience
  const [tempExperienceLocation, setTempExperienceLocation] = useState(experienceLocation || "");
  const [tempExperienceFrom, setTempExperienceFrom] = useState<Date | null>(experienceFrom);
  const [tempExperienceTo, setTempExperienceTo] = useState<Date | null>(experienceTo);
  const [tempExperienceAdults, setTempExperienceAdults] = useState(experienceAdults);
  const [tempExperienceChildren, setTempExperienceChildren] = useState(experienceChildren);
  const [tempExperienceInfants, setTempExperienceInfants] = useState(experienceInfants);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  // Formatear las fechas con dos dígitos, dependiendo del idioma
  const dateFormat: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short" };

  // Obtener la fecha de check-in y check-out para Stay (usando el contexto de Stay)
  const stayCheckIn = stayFrom
    ? stayFrom.toLocaleDateString(i18n.language, dateFormat)
    : t("anyWeek");

  const stayCheckOut = stayFrom && stayTo
    ? stayTo.toLocaleDateString(i18n.language, dateFormat)
    : "";

  const stayDateText = stayCheckOut ? `${stayCheckIn} - ${stayCheckOut}` : stayCheckIn;

  // Obtener la fecha de check-in y check-out para Experience (USANDO EL CONTEXTO)
  const experienceCheckIn = experienceFrom
    ? experienceFrom.toLocaleDateString(i18n.language, dateFormat)
    : t("anyWeek");

  const experienceCheckOut = experienceFrom && experienceTo
    ? experienceTo.toLocaleDateString(i18n.language, dateFormat)
    : "";

  const experienceDateText = experienceCheckOut ? `${experienceCheckIn} - ${experienceCheckOut}` : experienceCheckIn;

  // Calcular el total de huéspedes para Stay (usando el contexto de Stay)
  const totalStayGuests = adults + children + infants;
  const stayGuestsLabel = totalStayGuests > 0
    ? `${totalStayGuests} ${t("guests", { ns: "guestsInput" })}`
    : t("addGuests");

  // Calcular el total de huéspedes para Experience (USANDO EL CONTEXTO)
  const totalExperienceGuests = experienceAdults + experienceChildren + experienceInfants;
  const experienceGuestsLabel = totalExperienceGuests > 0
    ? `${totalExperienceGuests} ${t("guests", { ns: "guestsInput" })}`
    : t("addGuests");

  // Determinar si estamos en la página de experiencias
  const isExperiencePage = currentRoute.pathname === "/listing-experiences";

  // Seleccionar la ubicación y fechas según la página actual
  const selectedLocation = isExperiencePage ? experienceLocation : stayLocation;
  const selectedDateText = isExperiencePage
    ? `${experienceDateText} • ${experienceGuestsLabel}`
    : `${stayDateText} • ${stayGuestsLabel}`;

  // Función para manejar los datos de búsqueda para Stay
  const handleSearchStay = () => {
    // Actualizamos el contexto de Stay solo cuando el usuario haga clic en "Buscar"
    startTransition(() => {
      setStayLocation(tempStayLocation);  // Actualiza el contexto de Stay
      setStayFrom(tempStayFrom);
      setStayTo(tempStayTo);
      setAdults(tempStayAdults);
      setChildren(tempStayChildren);
      setInfants(tempStayInfants);
    });

    // Retrasar la navegación y el cierre del modal
    setTimeout(() => {
      navigate("/search"); // Redirigir a la página de búsqueda de Stay
      setShowModal(false); // Cerrar el modal
    }, 100); // 1 segundo de retraso
  };

  // Función para manejar los datos de búsqueda para Experience
  const handleSearchExperience = () => {
    // Actualizamos el contexto de Experiencia solo cuando el usuario haga clic en "Buscar"
    startTransition(() => {
      setExperienceLocation(tempExperienceLocation); // Actualiza el contexto de Experiencias
      setExperienceFrom(tempExperienceFrom);
      setExperienceTo(tempExperienceTo);
      setExperienceAdults(tempExperienceAdults); // Actualiza el contexto de adultos para experiencias
      setExperienceChildren(tempExperienceChildren); // Actualiza el contexto de niños para experiencias
      setExperienceInfants(tempExperienceInfants); // Actualiza el contexto de bebés para experiencias
    });

    // Retrasar la navegación y el cierre del modal
    setTimeout(() => {
      navigate("/listing-experiences"); // Redirigir a la página de experiencias
      setShowModal(false); // Cerrar el modal
    }, 100); // 1 segundo de retraso
  };

  // Función para manejar la búsqueda dependiendo de la pestaña actual
  const handleSearch = () => {
    if (currentTab === "stay") {
      handleSearchStay();
    } else if (currentTab === "experience") {
      handleSearchExperience();
    }
  };

  // Renderizar el botón para abrir el modal
  const renderButtonOpenModal = () => (
    <button
      onClick={openModal}
      className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 rounded-full shadow-lg"
      data-testid="search-bar-button"
    >
      {/* Logo a la izquierda */}
      <div
        className="flex items-center absolute left-4 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/"); // Navegar a la página principal
        }}
        data-testid="search-bar-logo"
      >
        <Logo className="" />
      </div>

      {/* Información de location, fechas y huéspedes en el centro */}
      <div className="flex flex-col items-center justify-center mx-auto" data-testid="search-bar-content">
        <span className="block font-semibold text-base lg:text-lg truncate" data-testid="search-bar-location">
          {selectedLocation || t("whereTo")}
        </span>
        <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-1" data-testid="search-bar-dates-guests">
            {selectedDateText}
          </span>
        </div>
      </div>

      {/* Icono de lupa a la derecha */}
      <div className="flex items-center absolute right-4">
        <MagnifyingGlassIcon className="w-5 h-5" data-testid="search-bar-icon" />
      </div>
    </button>
  );

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as="div" className="HeroSearchFormMobile__Dialog relative z-max" onClose={closeModal}>
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between">
                  <Tab.Group
                    selectedIndex={currentTab === "stay" ? 0 : 1}
                    onChange={(index) => setCurrentTab(index === 0 ? "stay" : "experience")}
                  >
                    {/* Botón para cerrar el modal */}
                    <div className="absolute left-4 top-4">
                      <button className="" onClick={closeModal}>
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    {/* Lista de Tabs */}
                    <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                      {["stay", "experiences"].map((item, index) => (
                        <Tab key={index} as={Fragment}>
                          {({ selected }) => (
                            <div className="relative">
                              <div className={`${selected ? "text-black dark:text-white" : ""}`}>
                                {t(item)} 
                              </div>
                              {selected && <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>}
                            </div>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>


                    {/* Paneles de Tabs */}
                    <Tab.Panels className="flex-1 overflow-y-auto py-4">
                      <Tab.Panel>
                        <StaySearchForm
                          tempLocation={tempStayLocation}
                          setTempLocation={setTempStayLocation}
                          tempFrom={tempStayFrom}
                          setTempFrom={setTempStayFrom}
                          tempTo={tempStayTo}
                          setTempTo={setTempStayTo}
                          tempAdults={tempStayAdults}
                          setTempAdults={setTempStayAdults}
                          tempChildren={tempStayChildren}
                          setTempChildren={setTempStayChildren}
                          tempInfants={tempStayInfants}
                          setTempInfants={setTempStayInfants}
                          onSearch={handleSearchStay}
                        />
                      </Tab.Panel>
                      <Tab.Panel>
                        <ExperienceSearchForm
                          tempExperienceLocation={tempExperienceLocation}
                          setTempExperienceLocation={setTempExperienceLocation}
                          tempFrom={tempExperienceFrom}
                          setTempFrom={setTempExperienceFrom}
                          tempTo={tempExperienceTo}
                          setTempTo={setTempExperienceTo}
                          tempExperienceAdults={tempExperienceAdults}
                          setTempExperienceAdults={setTempExperienceAdults}
                          tempExperienceChildren={tempExperienceChildren}
                          setTempExperienceChildren={setTempExperienceChildren}
                          tempExperienceInfants={tempExperienceInfants}
                          setTempExperienceInfants={setTempExperienceInfants}
                          onSearch={handleSearchExperience}
                        />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>

                  {/* Botones de Acciones */}
                  <div className="px-4 py-3 flex justify-between">
                    <button
                      className="underline font-semibold"
                      onClick={() => {
                        // Limpiar los estados temporales según la pestaña actual
                        if (currentTab === "stay") {
                          setTempStayLocation("");
                          setTempStayFrom(null);
                          setTempStayTo(null);
                          setTempStayAdults(1);
                          setTempStayChildren(0);
                          setTempStayInfants(0);
                        } else if (currentTab === "experience") {
                          setTempExperienceLocation("");
                          setTempExperienceFrom(null);
                          setTempExperienceTo(null);
                          setTempExperienceAdults(1);
                          setTempExperienceChildren(0);
                          setTempExperienceInfants(0);
                        }
                      }}
                    >
                      {t("clearAll")}
                    </button>
                    <button
                      type="button"
                      className="px-6 py-2 text-sm font-medium text-white bg-black rounded-lg"
                      onClick={() => {
                        handleSearch(); // Ejecutar la búsqueda según la pestaña actual
                        closeModal(); // Cerrar el modal después de hacer la búsqueda
                      }}
                    >
                      {t("search")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2Mobile;
