import React, { FC, useEffect, useRef, useState, startTransition } from "react";
import Logo from "shared/Logo/Logo";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import HeroSearchFormSmall, { SearchTab } from "components/HeroSearchFormSmall/HeroSearchFormSmall";
import { Link, useLocation } from "react-router-dom";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { useAuth } from "contexts/AuthContext";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import LangDropdown from "./LangDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import { useLocationContext } from "contexts/LocationContext";
import { useDatesContext } from "contexts/DatesContext";
import { useGuestsContext } from "contexts/GuestsContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import TabFilters from "containers/ListingStayPage/TabFilters/TabFilters";
import ExpTabFilters from "containers/ListingExperiencesPage/TabFilters/ExpTabFilters";
import FullScreenStaysMap from "containers/ListingStayPage/FullScreenStaysMap";
import FullScreenExperiencesMap from "containers/ListingExperiencesPage/FullScreenExperiencesMap";
import ExperiencesMobileFilterMenu from "containers/ListingExperiencesPage/TabFilters/ExperiencesMobileFilterMenu";
import ExperiencesSortByMobile from "containers/ListingExperiencesPage/TabFilters/ExperiencesSortByMobile";
import ExperiencesMoreFiltersMobile from "containers/ListingExperiencesPage/TabFilters/ExperiencesMoreFiltersMobile";

interface Header3Props {
  className?: string;
}

const Header3: FC<Header3Props> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation(); // Usar pathname directamente
  const { t, i18n } = useTranslation("header3");

  const [isFullScreenMap, setIsFullScreenMap] = useState(false);
  const [mapCenter, setMapCenter] = useState<[number, number]>([37.3886, -5.9823]);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const [selectedSortBy, setSelectedSortBy] = useState("featured");


  // Estados para Experiences
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<string[]>([]);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 2000]);

  // Log para depuración
  console.log("Current pathname:", pathname);

  // Detectar si estamos en las páginas específicas
  const isStayPage = pathname.toLowerCase() === "/search";
  const isExperiencesPage = pathname.toLowerCase() === "/listing-experiences";
  const isHomePage = pathname === "/" || pathname === "/login";

  // Contextos para alojamientos (Stay)
  const { location: contextLocation } = useLocationContext();
  const { from, to } = useDatesContext();
  const { adults, children, infants } = useGuestsContext();

  // Contextos para experiencias (Experiences)
  const { location: experienceLocation } = useExperienceLocationContext();
  const { from: experienceFrom, to: experienceTo } = useExperienceDatesContext();
  const { adults: expAdults, children: expChildren, infants: expInfants } = useExperienceGuestsContext();

  const [showHeroSearch, setShowHeroSearch] = useState<StaySearchFormFields | null>(null);
  const [currentTab, setCurrentTab] = useState<SearchTab>("Stays");
  const [showText, setShowText] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [activeInput, setActiveInput] = useState<StaySearchFormFields | null>(null);

  useOutsideAlerter(headerInnerRef, () => {
    startTransition(() => {
      setShowHeroSearch(null);
      setShowText(true);

      // Aplicar el cambio de currentTab después de 1 segundo
      setTimeout(() => {
        setCurrentTab("Stays");
      }, 500);
    });
  });

  useEffect(() => {
    startTransition(() => {
      setShowHeroSearch(null);
    });
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      startTransition(() => {
        setShowHeroSearch(null);
        setShowText(true);
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setCurrentTab(isExperiencesPage ? "Experiences" : "Stays");
  }, [isExperiencesPage]);

  const handleOpenSearch = (field: StaySearchFormFields) => {
    startTransition(() => {
      setShowHeroSearch(field);
      setActiveInput(field); // Establece el campo activo
      setShowText(false);
      setCurrentTab(isExperiencesPage ? "Experiences" : "Stays"); // Ajusta el tab según la página
    });
  };

  const renderHeroSearch = () => (
    <div
      className={`absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${showHeroSearch ? "visible opacity-100" : "-translate-y-10 opacity-0 invisible"
        }`}
      style={{
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "800px",
      }}
    >
      <div className="w-full max-w-4xl mx-auto pb-6 px-4">
        <HeroSearchFormSmall
          defaultFieldFocus={activeInput || undefined} // Usa el campo activo como enfoque inicial
          onTabChange={setCurrentTab}
          defaultTab={currentTab}
          searchValue={searchValue}
          onSearchChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
    </div>
  );

  const renderButtonOpenHeroSearch = () => {
    // Selección de ubicación dependiendo de la página
    const location = isExperiencesPage ? experienceLocation || t("location") : contextLocation || t("location");

    const dateFormat: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short" };

    // Selección de fechas según la página
    const checkIn = isExperiencesPage
      ? experienceFrom
        ? experienceFrom.toLocaleDateString(i18n.language, dateFormat)
        : t("checkIn")
      : from
        ? from.toLocaleDateString(i18n.language, dateFormat)
        : t("checkIn");

    const checkOut = isExperiencesPage
      ? experienceTo
        ? experienceTo.toLocaleDateString(i18n.language, dateFormat)
        : t("checkOut")
      : to
        ? to.toLocaleDateString(i18n.language, dateFormat)
        : t("checkOut");

    // Selección de número de invitados según la página
    const totalGuests = isExperiencesPage
      ? expAdults + expChildren + expInfants
      : adults + children + infants;

    const guestsLabel = `${totalGuests} ${t("guests", { ns: "guestsInput" })}`;

    return (
      <div
        ref={searchContainerRef}
        className="flex items-center justify-center flex-shrink-0 w-full max-w-md border border-neutral-200 rounded-full shadow hover:shadow-md transition-all px-1 cursor-pointer"
      >
        <div className="flex items-center w-full font-medium text-sm justify-between space-x-3 px-1.5 py-2">
          <span
            className="flex-[1.4] truncate text-center overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => handleOpenSearch("location")} // Abre HeroSearchFormSmall con el modal de ubicación
          >
            {location}
          </span>
          <span className="h-4 w-[1px] bg-neutral-300"></span>
          <span
            className="flex-[1.3] text-center whitespace-nowrap"
            onClick={() => handleOpenSearch("dates")} // Abre HeroSearchFormSmall con el modal de fechas
          >
            {checkIn} - {checkOut}
          </span>
          <span className="h-4 w-[1px] bg-neutral-300"></span>
          <span
            className="flex-[1.1] text-center whitespace-nowrap"
            onClick={() => handleOpenSearch("guests")} // Abre HeroSearchFormSmall con el modal de invitados
          >
            {guestsLabel}
          </span>
          <div className="flex-shrink-0">
            <span
              className="w-7 h-7 flex items-center justify-center rounded-full bg-primary-400"
              style={{ color: "white" }}
            >
              <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 transition-opacity will-change-[opacity] ${showHeroSearch ? "visible" : "invisible opacity-0 pointer-events-none"
          }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
        <div
          className={`absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity] 
      bg-transparent lg:bg-white dark:bg-neutral-900 ${showHeroSearch ? "duration-75" : ""}`}
        ></div>

        <div className="relative px-4 lg:container flex items-center justify-between">
          {/* Solo visible en pantallas grandes */}
          <div className={`flex-shrink-0 hidden lg:flex ${showHeroSearch ? "invisible" : "visible"}`}>
            <Logo />
          </div>

          <div className={`flex-grow max-w-lg hidden lg:flex justify-center ${showHeroSearch ? "invisible" : "visible"}`}>
            {!isHomePage && (
              <div className="hidden lg:block">
                {renderButtonOpenHeroSearch()}
              </div>
            )}
            {renderHeroSearch()}
          </div>

          <div className={`flex-shrink-0 flex space-x-2 items-center hidden lg:flex ${showHeroSearch ? "invisible" : "visible"}`}>
            <LangDropdown />
            <CurrencyDropdown />
          </div>

          <div className={`flex-shrink-0 flex items-center space-x-4 hidden lg:flex ${showHeroSearch ? "invisible" : "visible"}`}>
            <Link
              to="/add-listing-1"
              className="hidden xl:inline-flex text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none"
              style={{ whiteSpace: "nowrap" }}
            >
              {t("listYourProperty")}
            </Link>
            {isAuthenticated && (
              <div className="pr-1.5">
                <NotifyDropdown className="-ml-2 xl:-ml-1" />
              </div>
            )}
            {isAuthenticated ? (
              <AvatarDropdown />
            ) : (
              <ButtonPrimary
                href="/login"
                className="px-4 py-2 text-sm text-white rounded-full focus:outline-none min-w-[100px] bg-primary-400 hover:bg-primary-hover"
              >
                {t("logIn")}
              </ButtonPrimary>
            )}
          </div>
        </div>

        {/* Móvil - HeroSearchForm2MobileFactory */}
        <div className="lg:hidden bg-white w-full max-w-lg mx-auto mt-0 rounded-full z-50 relative">
          <HeroSearchForm2MobileFactory />
        </div>

        {/* Móvil - Barra de menú de filtros detrás y expandida */}
        {isStayPage && (
          <div className="lg:hidden bg-white w-full absolute top-0 z-40 pt-24">
            <TabFilters
              onMapClick={() => setIsFullScreenMap(true)}
              isMapOpen={isFullScreenMap}
            />
          </div>
        )}

        {isExperiencesPage && (
          <div className="lg:hidden bg-white w-full absolute top-0 z-40 pt-24">
          <ExpTabFilters
            onMapClick={() => setIsFullScreenMap(true)}
            isMapOpen={isFullScreenMap}
          />
        </div>
      )}

        {/* Mapa en pantalla completa */}
        {isStayPage && (
          <FullScreenStaysMap
            isMapVisible={isFullScreenMap}
            onClose={() => setIsFullScreenMap(false)}
            mapCenter={mapCenter}
            setMapCenter={setMapCenter}
          />
        )}

        {isExperiencesPage && (
          <FullScreenExperiencesMap
            isMapVisible={isFullScreenMap}
            onClose={() => setIsFullScreenMap(false)}
            mapCenter={mapCenter}
            setMapCenter={setMapCenter}
            isFiltersModalOpen={isFiltersModalOpen}
            setIsFiltersModalOpen={setIsFiltersModalOpen}
            selectedTypes={selectedTypes}
            selectedTimeOfDay={selectedTimeOfDay}
            priceRange={priceRange}
            setSelectedTypes={setSelectedTypes}
            setSelectedTimeOfDay={setSelectedTimeOfDay}
            setPriceRange={setPriceRange}
          />
        )}
      </header>
    </>
  );
};

export default Header3;