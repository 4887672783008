import React, { createContext, useContext, useEffect, useState } from "react";
import { openDB } from "idb";

interface ExperiencesFilterContextType {
  priceRange: number[];
  selectedTypes: string[];
  selectedTimeOfDay: string[];
  selectedPuntuacion: string[];
  sortBy: string;
  isSaleOffActive: boolean;
  setPriceRange: (value: number[]) => void;
  setSelectedTypes: (value: string[]) => void;
  setSelectedTimeOfDay: (value: string[]) => void;
  setSelectedPuntuacion: (value: string[]) => void;
  setSortBy: (value: string) => void;
  toggleSaleOffFilter: () => void;
}

const DB_NAME = "ExperiencesFilterDB";
const STORE_NAME = "filters";

const ExperiencesFilterContext = createContext<ExperiencesFilterContextType | undefined>(undefined);

export const ExperiencesFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<{
    priceRange: number[];
    selectedTypes: string[];
    selectedTimeOfDay: string[];
    selectedPuntuacion: string[];
    isSaleOffActive: boolean;
    sortBy: string;
  } | null>(null); // Cambiado a null inicialmente para manejar la carga

  const [isLoading, setIsLoading] = useState(true);

  const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const loadFiltersFromDB = async () => {
    const db = await initDB();
    const savedFilter = await db.get(STORE_NAME, 1);
    return savedFilter || {
      priceRange: [0, 2000],
      selectedTypes: [],
      selectedTimeOfDay: [],
      selectedPuntuacion: [],
      isSaleOffActive: false,
      sortBy: "featured",
    };
  };

  const saveFiltersToDB = async (newFilters: any) => {
    const db = await initDB();
    await db.put(STORE_NAME, { id: 1, ...newFilters });
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const storedFilters = await loadFiltersFromDB();
      setFilters(storedFilters);
      setIsLoading(false);
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    if (filters) {
      saveFiltersToDB(filters);
    }
  }, [filters]);

  const setPriceRange = (value: number[]) => {
    setFilters((prev) => ({ ...prev!, priceRange: value }));
  };

  const setSelectedTypes = (types: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedTypes: types }));
  };

  const setSelectedTimeOfDay = (times: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedTimeOfDay: times }));
  };

  const setSelectedPuntuacion = (ratings: string[]) => {
    setFilters((prev) => ({ ...prev!, selectedPuntuacion: ratings }));
  };

  const toggleSaleOffFilter = () => {
    setFilters((prev) => ({ ...prev!, isSaleOffActive: !prev!.isSaleOffActive }));
  };

  const setSortBy = (value: string) => {
    setFilters((prev) => ({ ...prev!, sortBy: value }));
  };  

  if (isLoading) {
    return null; // Puedes reemplazar esto con un loader
  }

  return (
    <ExperiencesFilterContext.Provider
      value={{
        priceRange: filters!.priceRange,
        selectedTypes: filters!.selectedTypes,
        selectedTimeOfDay: filters!.selectedTimeOfDay,
        selectedPuntuacion: filters!.selectedPuntuacion,
        isSaleOffActive: filters!.isSaleOffActive,
        sortBy: filters!.sortBy,
        setPriceRange,
        setSelectedTypes,
        setSelectedTimeOfDay,
        setSelectedPuntuacion,
        setSortBy,
        toggleSaleOffFilter,
      }}
    >
      {children}
    </ExperiencesFilterContext.Provider>
  );
};

export const useExperiencesFilterContext = () => {
  const context = useContext(ExperiencesFilterContext);
  if (!context) {
    throw new Error("useExperiencesFilterContext must be used within an ExperiencesFilterProvider");
  }
  return context;
};
