import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useTransition } from "react";
import { useTranslation } from "react-i18next";

export interface GuestsInputProps {
  onChange?: (data: { guestAdults: number; guestChildren: number; guestInfants: number }) => void;
  className?: string;
  tempAdults: number;
  tempChildren: number;
  tempInfants: number;
  setTempAdults: React.Dispatch<React.SetStateAction<number>>;
  setTempChildren: React.Dispatch<React.SetStateAction<number>>;
  setTempInfants: React.Dispatch<React.SetStateAction<number>>;
}

const GuestsInput: FC<GuestsInputProps> = ({
  onChange,
  className = "",
  tempAdults,
  tempChildren,
  tempInfants,
  setTempAdults,
  setTempChildren,
  setTempInfants,
}) => {
  const { t } = useTranslation("guestsInput");

  const [isPending, startTransition] = useTransition();  // Usamos useTransition aquí

  // Manejar cambios en los estados temporales
  const handleChangeData = (value: number, type: "guestAdults" | "guestChildren" | "guestInfants") => {
    const newData = {
      guestAdults: tempAdults,
      guestChildren: tempChildren,
      guestInfants: tempInfants,
    };

    startTransition(() => {  // Hacemos la actualización dentro de startTransition
      if (type === "guestAdults") {
        setTempAdults(value);
        newData.guestAdults = value;
      } else if (type === "guestChildren") {
        setTempChildren(value);
        newData.guestChildren = value;
      } else if (type === "guestInfants") {
        setTempInfants(value);
        newData.guestInfants = value;
      }

      // Notificar cambios al componente padre
      onChange && onChange(newData);
    });
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {t("whosComing")}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={tempAdults}
        onChange={(value) => handleChangeData(value, "guestAdults")}
        max={20}
        min={1}
        label={t("adults")}
        desc={t("adultsDesc")}
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={tempChildren}
        onChange={(value) => handleChangeData(value, "guestChildren")}
        max={20}
        min={0}
        label={t("children")}
        desc={t("childrenDesc")}
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={tempInfants}
        onChange={(value) => handleChangeData(value, "guestInfants")}
        max={20}
        min={0}
        label={t("infants")}
        desc={t("infantsDesc")}
      />
    </div>
  );
};

export default GuestsInput;