import React, { FC, useEffect, useState, useTransition } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import StayCard from "components/StayCard/StayCard";
import countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";

const ITEMS_PER_PAGE = 8;
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS;

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: React.ReactNode;
  subHeading?: React.ReactNode;
  headingIsCenter?: boolean;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading,
  subHeading,
  headingIsCenter,
}) => {
  const { t, i18n } = useTranslation("sectionGridFeaturePlaces");
  const [currentPage, setCurrentPage] = useState(1);
  const [isPending, startTransition] = useTransition();
  const [userCountry, setUserCountry] = useState<string>("");

  const totalPages = Math.ceil(stayListings.length / ITEMS_PER_PAGE);
  const paginatedData = stayListings.slice(0, currentPage * ITEMS_PER_PAGE);

  const handleShowMore = () => {
    if (currentPage < totalPages) {
      startTransition(() => {
        setCurrentPage(currentPage + 1);
      });
    }
  };

  const renderCard = (stay: StayDataType) => (
    <StayCard key={stay.id} data={stay} />
  );

  useEffect(() => {
    // Registrar idiomas para traducción de países
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

    const fetchUserLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const countryCode = data.country_code; // Obtiene el código del país (e.g., "ES", "US")
        const translatedCountry = countries.getName(
          countryCode,
          i18n.language
        ); // Traduce el nombre del país
        setUserCountry(translatedCountry || t("defaultCountry"));
      } catch (error) {
        console.error("Error al obtener el país del usuario:", error);
        setUserCountry(t("defaultCountry"));
      }
    };

    fetchUserLocation();
  }, [i18n.language, t]);

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <Heading2
        heading={
          heading || `${t("featuredStaysIn")} ${userCountry}`
        }
        subHeading={subHeading || t("subHeading")}
        className={headingIsCenter ? "text-center" : ""}
      />

      <div
        className={`grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {paginatedData.map((stay) => renderCard(stay))}
      </div>

      {currentPage < totalPages && (
        <div className="flex mt-10 justify-center">
          <ButtonPrimary onClick={handleShowMore} disabled={isPending}>
            {isPending ? t("loading") : t("showMoreButton")}
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
};

export default SectionGridFeaturePlaces;
