import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary"; // Asegúrate de que ButtonPrimary está importado
import LangDropdown from "components/Header/LangDropdown";
import CurrencyDropdown from "components/Header/CurrencyDropdown"; // Importando el selector de moneda
import { Link } from "react-router-dom"; // Importando Link para usarlo como un enlace
import { useTranslation } from "react-i18next"; // Usamos el hook de i18next

export interface NavMobileProps {
  data?: any[]; // Puedes cambiar NavItemType a cualquier tipo que estés usando
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data,
  onClickClose,
}) => {
  // Usamos el hook de i18next para obtener las traducciones del namespace "navMobile"
  const { t } = useTranslation("navMobile");

  const _renderItem = (item: any, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive ? "text-secondary" : ""
            }`
          }
          to={item.href || "#"} // "Acerca de" no tiene enlace, solo usa '#'
        >
          <span className="py-2.5 pr-3 block w-full">{t(`menuItems.${item.name}`)}</span>
        </NavLink>
      </Disclosure>
    );
  };

  // Definir los ítems del menú
  const menuItems = [
    { id: 1, name: "main", href: "/" },
    { id: 2, name: "about", href: "#" }, // "Acerca de" no tiene enlace
  ];

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>{t("description")}</span>

          {/* Convertir el botón en un enlace */}
          <div className="flex justify-between items-center mt-8">
            <Link to="/add-listing-1">
              <ButtonPrimary className="w-full">
                {t("button")}
              </ButtonPrimary>
            </Link>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {menuItems.map((item) => _renderItem(item, item.id))}
      </ul>
      <div className="flex items-center justify-between py-6 px-5">
        {/* Agrupar los dropdowns de idioma y moneda, el CurrencyDropdown pegado a la derecha */}
        <div className="flex justify-between items-center w-full">
          <LangDropdown 
            panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 left-0 top-full sm:px-0" 
          />
          <div className="ml-4"> {/* Añadimos margen izquierdo para separación */}
            <CurrencyDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
