import React from "react";
import { useTranslation } from "react-i18next";

const ExperiencesMobileFilterMenu: React.FC<{
  onFiltersExpClick: () => void;
  onSortExpClick: () => void;
  onMapExpClick: () => void;
  isMapExpOpen: boolean;
}> = ({ onFiltersExpClick, onSortExpClick, onMapExpClick, isMapExpOpen }) => {
  const { t } = useTranslation("mobileMenu");

  return (
    <div className="relative z-10 w-full"> 
  <div className="flex justify-between items-center py-2 px-6 border-b">
    {/* Opción Filtros */}
    <button
      className="flex flex-col items-center justify-center text-sm text-neutral-700 hover:text-primary-500 focus:outline-none"
      onClick={onFiltersExpClick}
    >
      <i className="las la-sliders-h text-2xl"></i>
      <span className="min-w-[80px] text-center">{t("filters")}</span>
    </button>

    {/* Opción Ordenar por */}
    <button
      className="flex flex-col items-center justify-center text-sm text-neutral-700 hover:text-primary-500 focus:outline-none"
      onClick={onSortExpClick}
    >
      <i className="las la-sort-amount-down text-2xl"></i>
      <span className="min-w-[80px] text-center">{t("sortBy")}</span>
    </button>

    {/* Opción Mapa */}
    <button
  className="flex flex-col items-center justify-center text-sm text-neutral-700 hover:text-primary-500 focus:outline-none"
  onClick={onMapExpClick} // Alterna la vista de mapa completo
>
  <i className="las la-map text-2xl"></i>
  <span className="w-[90px] text-center">
    {isMapExpOpen ? t("closeMap") : t("map")}
  </span>
</button>

  </div>
</div>

  );
};

export default ExperiencesMobileFilterMenu;
