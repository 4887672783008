import React, { FC } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L, { DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import locationSvg from "../containers/assets/location.svg";

interface SimpleMapProps {
  lat: number;
  lng: number;
}

const SimpleMap: FC<SimpleMapProps> = ({ lat, lng }) => {
    const customIcon = new DivIcon({
        html: `
          <img 
            src="${locationSvg}" 
            alt="Location Marker" 
            style="width: 30px; height: 45px;" 
          />
        `,
        className: "",
        iconSize: [30, 45], // Tamaño del icono
        iconAnchor: [15, 45], // Punto de anclaje (punta del marcador)
      });
      

  return (
    <MapContainer
      center={[lat, lng]}
      zoom={13}
      style={{ width: "100%", height: "100%", borderRadius: "10px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* Marcador con icono personalizado */}
      <Marker position={[lat, lng]} icon={customIcon} />
    </MapContainer>
  );
};

export default SimpleMap;
