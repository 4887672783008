import React, { FC, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useTranslation } from "react-i18next";
import { useGuestsContext } from "contexts/GuestsContext";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
  isOpen: boolean; // Estado de apertura controlado por el padre
  onOpen: () => void; // Notificar al padre sobre la apertura
  validateFields: () => boolean;
  onSearchClick?: () => void;
}

const GuestsInputSmall: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/search",
  hasButtonSubmit = true,
  isOpen,
  onOpen,
  validateFields,
  onSearchClick,
}) => {
  const { t } = useTranslation("guestsInput");
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();

  const handleChangeData = (value: number, type: "adults" | "children" | "infants") => {
    if (type === "adults") setAdults(value);
    else if (type === "children") setChildren(value);
    else if (type === "infants") setInfants(value);
  };

  const handleClearGuests = () => {
    setAdults(1);
    setChildren(0);
    setInfants(0);
  };

  const totalGuests = adults + children + infants;

  const handleButtonClick = (): boolean => {
    if (!validateFields()) {
      return false;
    }
    if (onSearchClick) {
      onSearchClick();
    }
    return true;
  };

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          {/* Usa `isOpen || open` para controlar la visibilidad */}
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              isOpen || open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClick={onOpen}
            >
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} {t("guests")}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? t("guests") : t("addGuests")}
                </span>
              </div>
              {!!totalGuests && (isOpen || open) && <ClearDataButton onClick={handleClearGuests} />}
            </Popover.Button>

            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} onClick={handleButtonClick} />
              </div>
            )}
          </div>

          {(isOpen || open) && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            show={isOpen || open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={adults}
                onChange={(value) => handleChangeData(value, "adults")}
                max={10}
                min={1}
                label={t("adults")}
                desc={t("adultsDesc")}
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={children}
                onChange={(value) => handleChangeData(value, "children")}
                max={4}
                min={0}
                label={t("children")}
                desc={t("childrenDesc")}
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={infants}
                onChange={(value) => handleChangeData(value, "infants")}
                max={4}
                min={0}
                label={t("infants")}
                desc={t("infantsDesc")}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInputSmall;
