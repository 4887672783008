import React, { FC, Fragment, useRef, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useTranslation } from "react-i18next";
import { useGuestsContext } from "contexts/GuestsContext";
import useOutsideAlerter from "hooks/useOutsideAlerter";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
  validateFields?: () => boolean;
  onOpen: () => void;
  onClose?: () => void;
  isOpen?: boolean; // Prop para controlar la visibilidad externa
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/search",
  hasButtonSubmit = true,
  validateFields,
  onOpen,
  onClose,
  isOpen = false, // Valor por defecto si no se pasa
}) => {
  const { t } = useTranslation("guestsInput");
  const { adults, children, infants, setAdults, setChildren, setInfants } = useGuestsContext();
  const refContainer = useRef<HTMLDivElement>(null);

  const handleChangeData = (value: number, type: "adults" | "children" | "infants") => {
    if (type === "adults") setAdults(value);
    else if (type === "children") setChildren(value);
    else if (type === "infants") setInfants(value);
  };

  const handleClearGuests = () => {
    setAdults(1);
    setChildren(0);
    setInfants(0);
  };

  const totalGuests = adults + children + infants;

  const handleButtonClick = () => {
    if (!validateFields || validateFields()) {
      return true;
    }
    return false;
  };

  // Cerrar el popover al hacer clic fuera o presionar Escape
  useOutsideAlerter(refContainer, () => {
    if (onClose) onClose();
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && onClose) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <Popover className={`flex relative ${className}`}>
      {() => (
        <>
          <div
            ref={refContainer}
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              isOpen ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClick={onOpen}
            >
              <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7 text-neutral-300 dark:text-neutral-400" />
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} {t("guests")}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? t("guests") : t("addGuests")}
                </span>
              </div>
              {!!totalGuests && isOpen && <ClearDataButton onClick={handleClearGuests} />}
            </Popover.Button>

            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} onClick={handleButtonClick} />
              </div>
            )}
          </div>

          {isOpen && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            show={isOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={adults}
                onChange={(value) => handleChangeData(value, "adults")}
                max={10}
                min={1}
                label={t("adults")}
                desc={t("adultsDesc")}
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={children}
                onChange={(value) => handleChangeData(value, "children")}
                max={4}
                min={0}
                label={t("children")}
                desc={t("childrenDesc")}
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={infants}
                onChange={(value) => handleChangeData(value, "infants")}
                max={4}
                min={0}
                label={t("infants")}
                desc={t("infantsDesc")}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
