import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

interface ExperiencesMoreFiltersMobileProps {
  selectedTypes: string[];
  selectedTimeOfDay: string[];
  priceRange: number[];
  onApply: (types: string[], timeOfDay: string[], priceRange: number[]) => void;
  onClear: () => void;
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
}

const ExperiencesMoreFiltersMobile: React.FC<ExperiencesMoreFiltersMobileProps> = ({
  selectedTypes = [],
  selectedTimeOfDay = [],
  priceRange = [0, 2000],
  onApply,
  onClear,
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation("mobileExperiencesFilters");

  // Estados temporales para manejar los filtros en el modal
  const [tempSelectedTypes, setTempSelectedTypes] = useState<string[]>(selectedTypes);
  const [tempSelectedTimeOfDay, setTempSelectedTimeOfDay] = useState<string[]>(selectedTimeOfDay);
  const [tempPriceRange, setTempPriceRange] = useState<number[]>(priceRange);
  const [fieldNameShow, setFieldNameShow] = useState<"typeOfExperience" | "priceRange" | "timeOfDay" | null>(null);

  // Sincronizar los valores iniciales del modal con el contexto al abrirlo
  useEffect(() => {
    if (isOpen) {
      setTempSelectedTypes(selectedTypes);
      setTempSelectedTimeOfDay(selectedTimeOfDay);
      setTempPriceRange(priceRange);
    }
  }, [isOpen, selectedTypes, selectedTimeOfDay, priceRange]);

  // Manejar cambios en los filtros temporales
  const handleTypeChange = (type: string) => {
    setTempSelectedTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const handleTimeOfDayChange = (time: string) => {
    setTempSelectedTimeOfDay((prev) =>
      prev.includes(time) ? prev.filter((t) => t !== time) : [...prev, time]
    );
  };

  // Aplicar los filtros y cerrar el modal
  const handleApply = () => {
    onApply(tempSelectedTypes, tempSelectedTimeOfDay, tempPriceRange);
    closeModal();
  };

  // Limpiar los filtros temporales
  const handleClear = () => {
    setTempSelectedTypes([]);
    setTempSelectedTimeOfDay([]);
    setTempPriceRange([0, 2000]);
    onClear();
  };

  // Renderizar secciones del modal
  const renderSection = (title: string, type: string, content: React.ReactNode) => (
    <div className={`w-full ${fieldNameShow === type ? "rounded-2xl shadow-lg" : "rounded-xl shadow-sm"}`}>
      <button
        className="w-full flex justify-between text-sm font-medium p-4"
        onClick={() => setFieldNameShow(fieldNameShow === type ? null : (type as any))}
      >
        <span>{t(title)}</span>
      </button>
      {fieldNameShow === type && <div className="px-4 py-2">{content}</div>}
    </div>
  );

  // Nueva implementación de renderTypeOfExperience con botones
  const renderTypeOfExperience = () => (
    <div className="flex flex-wrap gap-4 w-full">
      {[
        { name: "foodAndDrinks", labelKey: "foodAndDrinks", icon: "🥣" },
        { name: "artAndCulture", labelKey: "artAndCulture", icon: "🎨" },
        { name: "natureAndOutdoors", labelKey: "natureAndOutdoors", icon: "🌳" },
        { name: "sports", labelKey: "sports", icon: "⚽" },
      ].map((item) => {
        const isActive = tempSelectedTypes.includes(item.name);
        return (
          <button
            key={item.name}
            onClick={() => handleTypeChange(item.name)}
            className={`flex items-center space-x-2 px-4 py-2 text-sm rounded-full border focus:outline-none ${
              isActive
                ? "border-primary-500 bg-primary-100 text-primary-500"
                : "border-neutral-300 hover:border-neutral-400"
            }`}
          >
            {/* Ícono */}
            <span className="text-sm">{item.icon}</span>
            {/* Texto */}
            <span>{t(item.labelKey)}</span>
            {/* Botón de eliminar (solo cuando está activo) */}
            {isActive && (
              <span
                className="ml-2 w-4 h-4 flex items-center justify-center rounded-full bg-primary-500 text-white text-xs font-bold"
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el clic en la "x" afecte el botón principal
                  handleTypeChange(item.name);
                }}
              >
                ×
              </span>
            )}
          </button>
        );
      })}
    </div>
  );

  // Nueva implementación de renderTimeOfDay con botones
  const renderTimeOfDay = () => (
    <div className="flex flex-wrap gap-4 w-full">
      {[
        { name: "morning", labelKey: "morning", icon: "🌅" },
        { name: "afternoon", labelKey: "afternoon", icon: "🌞" },
        { name: "evening", labelKey: "evening", icon: "🌙" },
      ].map((item) => {
        const isActive = tempSelectedTimeOfDay.includes(item.name);
        return (
          <button
            key={item.name}
            onClick={() => handleTimeOfDayChange(item.name)}
            className={`flex items-center space-x-2 px-4 py-2 text-sm rounded-full border focus:outline-none ${
              isActive
                ? "border-primary-500 bg-primary-100 text-primary-500"
                : "border-neutral-300 hover:border-neutral-400"
            }`}
          >
            {/* Ícono */}
            <span className="text-sm">{item.icon}</span>
            {/* Texto */}
            <span>{t(item.labelKey)}</span>
            {/* Botón de eliminar (solo cuando está activo) */}
            {isActive && (
              <span
                className="ml-2 w-4 h-4 flex items-center justify-center rounded-full bg-primary-500 text-white text-xs font-bold"
                onClick={(e) => {
                  e.stopPropagation(); // Evita que el clic en la "x" afecte el botón principal
                  handleTimeOfDayChange(item.name);
                }}
              >
                ×
              </span>
            )}
          </button>
        );
      })}
    </div>
  );

  const renderPriceRange = () => (
    <div className="space-y-8">
      <Slider
        range
        min={0}
        max={2000}
        value={tempPriceRange}
        allowCross={false}
        onChange={(value) => setTempPriceRange(value as number[])}
      />
      <div className="flex justify-between space-x-5">
        <div className="w-1/2">
          <label className="block text-sm font-medium">{t("minPrice")}</label>
          <input
            type="number"
            className="block w-full pl-3 pr-3 sm:text-sm border rounded-full"
            value={tempPriceRange[0]}
            onChange={(e) => setTempPriceRange([Number(e.target.value), tempPriceRange[1]])}
          />
        </div>
        <div className="w-1/2">
          <label className="block text-sm font-medium">{t("maxPrice")}</label>
          <input
            type="number"
            className="block w-full pl-3 pr-3 sm:text-sm border rounded-full"
            value={tempPriceRange[1]}
            onChange={(e) => setTempPriceRange([tempPriceRange[0], Number(e.target.value)])}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle">&#8203;</span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-[calc(100vh-50px)] dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100">
              {/* Header */}
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title as="h3" className="text-lg font-medium">{t("title")}</Dialog.Title>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={closeModal} />
                </span>
              </div>

              {/* Contenido con scroll vertical */}
              <div className="flex-grow overflow-y-auto px-6 space-y-4">
                {renderSection("typeOfExperience", "typeOfExperience", renderTypeOfExperience())}
                {renderSection("priceRange", "priceRange", renderPriceRange())}
                {renderSection("timeOfDay", "timeOfDay", renderTimeOfDay())}
              </div>

              {/* Footer */}
              <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                <ButtonThird onClick={handleClear}>{t("clear")}</ButtonThird>
                <ButtonPrimary onClick={handleApply}>{t("apply")}</ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ExperiencesMoreFiltersMobile;