import React, { FC, useEffect, useState, useRef } from "react";
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters/TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useTranslation } from "react-i18next";
import { useDatesContext } from "contexts/DatesContext";
import { useFiltersContext } from "contexts/FilterContext";
import { useLocationContext } from "contexts/LocationContext";
import { useGuestsContext } from "contexts/GuestsContext";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}


const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS;
const ITEMS_PER_PAGE = 8;

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t, i18n } = useTranslation("heading2");
  const { from, to } = useDatesContext();
  const { location } = useLocationContext();
  const { adults, children, infants } = useGuestsContext();
  const {
    priceRange,
    selectedTypes,
    bedroomsCount,
    bathroomsCount,
    selectedAmenities,
    selectedFacilities,
    sortBy,
  } = useFiltersContext();

  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const tabFiltersRef = useRef<HTMLDivElement>(null);

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isFullScreenMap, setIsFullScreenMap] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [mapCenter, setMapCenter] = useState<[number, number]>([37.3886, -5.9823]); // Default center

  // Formato de fechas
  const dateFormatOptions: Intl.DateTimeFormatOptions = { month: "short", day: "2-digit" };
  const formattedFromDate = from ? from.toLocaleDateString(i18n.language, dateFormatOptions) : "";
  const formattedToDate = to ? to.toLocaleDateString(i18n.language, dateFormatOptions) : "";

  const totalGuests = adults + children + infants;

  const handleResize = () => setIsMobile(window.innerWidth < 1280);

  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) {
        setIsSticky(false);
        return;
      }

      const tabFiltersTop = tabFiltersRef.current?.offsetTop || 0;
      const currentScroll = window.scrollY;

      setIsSticky(currentScroll >= tabFiltersTop);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    setCurrentPage(1);
  }, [priceRange, selectedTypes, bedroomsCount, bathroomsCount, selectedAmenities, selectedFacilities]);

  // Filtrar y ordenar datos
  const filteredData = data.filter((stay) => {
    const isWithinPriceRange = stay.price >= priceRange[0] && stay.price <= priceRange[1];
    const isSelectedType = selectedTypes.length === 0 || selectedTypes.includes(stay.type);
    const isWithinBedroomsCount = bedroomsCount === 0 || stay.bedrooms >= bedroomsCount;
    const isWithinBathroomsCount = bathroomsCount === 0 || stay.bathrooms >= bathroomsCount;
    const hasSelectedAmenities =
      selectedAmenities.length === 0 ||
      selectedAmenities.every((amenity) => stay.amenities.includes(amenity));
    const hasSelectedFacilities =
      selectedFacilities.length === 0 ||
      selectedFacilities.every((facility) => stay.facilities.includes(facility));
    const isFeatured = sortBy === "featured" ? stay.featured === true : true;

    return (
      isWithinPriceRange &&
      isSelectedType &&
      isWithinBedroomsCount &&
      isWithinBathroomsCount &&
      hasSelectedAmenities &&
      hasSelectedFacilities &&
      isFeatured
    );
  });

  const sortedData = [...filteredData].sort((a, b) => {
    switch (sortBy) {
      case "lowestPrice":
        return a.price - b.price;
      case "highestPrice":
        return b.price - a.price;
      case "reviewStart":
        return b.reviewStart - a.reviewStart;
      case "reviewCount":
        return (b.reviewCount || 0) - (a.reviewCount || 0);
      case "featured":
        return b.featured === true ? 1 : -1;
      default:
        return 0;
    }
  });

  const totalPages = Math.ceil(sortedData.length / ITEMS_PER_PAGE);
  const paginatedData = sortedData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page: number) => setCurrentPage(page);

  console.log("Data passed to SectionGridFilterCard:", data);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className} ${isMobile ? "pt-16" : ""}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* Heading */}
      <Heading2
        onToggleMap={onToggleMap}
        isMapVisible={isMapVisible}
        heading={`${t("staysIn")} ${location || t("destination")}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {formattedFromDate} - {formattedToDate}
            <span className="mx-2">·</span>
            {t("numberOfGuests", { count: totalGuests })}
          </span>
        }
      />

      {/* TabFilters */}
      {!isMobile && (
        <div className="w-full px-0 pb-8" ref={tabFiltersRef}>
          <TabFilters
            onMapClick={() => setIsFullScreenMap(true)}
            isMapOpen={isFullScreenMap}
          />
        </div>
      )}

      {/* List of StayCards */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {paginatedData.length === 0 ? (
          <p>{t("noCards")}</p>
        ) : (
          paginatedData.map((stay) => <StayCard key={stay.id} data={stay} />)
        )}
      </div>

      {/* Pagination */}
      <div className="flex mt-16 justify-center items-center">
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
