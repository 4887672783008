import React, { FC, useState, useEffect } from "react";
import LocationInputExperiences from "../LocationInputExperiences";
import GuestsInputExperiences from "../GuestsInputExperiences";
import ExperiencesDateSingleInput from "./ExperiencesDateSingleInput";
import { useExperienceDatesContext } from "contexts/ExperienceDatesContext";
import { useExperienceGuestsContext } from "contexts/ExperienceGuestsContext";
import { useExperienceLocationContext } from "contexts/ExperienceLocationContext";
import { useTranslation } from "react-i18next";

export interface ExperiencesSearchFormProps {}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = () => {
  const { t } = useTranslation("errors");
  const { from, to, setFrom, setTo } = useExperienceDatesContext();
  const { adults, children, infants, setAdults, setChildren, setInfants } = useExperienceGuestsContext();
  const { location, setLocation } = useExperienceLocationContext();

  const [locationError, setLocationError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isDateInputOpen, setIsDateInputOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);

  // Manejar apertura de modales
  const handleOpenModal = (modalName: string) => {
    setIsLocationOpen(modalName === "location");
    setIsDateInputOpen(modalName === "dateInput");
    setIsGuestsOpen(modalName === "guests");
  };

  // Cerrar todos los modales
  const closeAllModals = () => {
    setIsLocationOpen(false);
    setIsDateInputOpen(false);
    setIsGuestsOpen(false);
  };

  // Detectar Escape y clic fuera del modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeAllModals();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (
        !target.closest(".location-input-experiences") &&
        !target.closest(".experiences-date-input") &&
        !target.closest(".guests-input-experiences")
      ) {
        closeAllModals();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Guardar estado en localStorage
  const saveSearchFilterToLocalStorage = () => {
    const searchFilter = {
      location,
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      adult: adults,
      children,
      infant: infants,
    };
    localStorage.setItem("searchFilterExperiences", JSON.stringify(searchFilter));
  };

  // Sincronizar con cambios en localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const savedFilter = JSON.parse(localStorage.getItem("searchFilterExperiences") || "{}");

      setFrom(savedFilter.from ? new Date(savedFilter.from) : null);
      setTo(savedFilter.to ? new Date(savedFilter.to) : null);
      setAdults(savedFilter.adult || 2);
      setChildren(savedFilter.children || 0);
      setInfants(savedFilter.infant || 0);
      setLocation(savedFilter.location || "");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setFrom, setTo, setAdults, setChildren, setInfants, setLocation]);

  // Manejar selección de ubicación
  const handleLocationSelected = (selectedLocation: string) => {
    setLocation(selectedLocation);
    setLocationError(false);
    saveSearchFilterToLocalStorage();

    // Abrir automáticamente el modal de fechas
    setIsLocationOpen(false);
    setIsDateInputOpen(true);
  };

  // Manejar cambios de fecha
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setFrom(startDate);
    setTo(endDate);
    setDateError(false);
    saveSearchFilterToLocalStorage();

    // Abrir automáticamente el modal de huéspedes si las fechas están seleccionadas
    if (startDate && endDate) {
      setIsDateInputOpen(false);
      setIsGuestsOpen(true);
    }
  };

  // Validar campos obligatorios
  const validateFields = () => {
    let isValid = true;

    // Si falta la ubicación, abrir el modal de ubicación
    if (!location) {
      setLocationError(true);
      setIsLocationOpen(true);
      setIsDateInputOpen(false);
      setIsGuestsOpen(false);
      isValid = false;
    }

    // Si faltan las fechas, abrir el modal de fechas después de la ubicación
    if (!from || !to) {
      setDateError(true);
      if (isValid) {
        setIsDateInputOpen(true);
        setIsLocationOpen(false);
        setIsGuestsOpen(false);
      }
      isValid = false;
    }

    // Asegurarse de que los modales no se cierren si hay errores
    if (!isValid) {
      setIsGuestsOpen(false);
    }

    return isValid;
  };

  return (
    <div className="w-full">
      <form className="relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        <LocationInputExperiences
          className="flex-[1.5] location-input-experiences"
          onLocationSelected={handleLocationSelected}
          isOpen={isLocationOpen}
          onOpen={() => handleOpenModal("location")}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

        <ExperiencesDateSingleInput
          className="flex-1 experiences-date-input"
          onDateChange={handleDateChange}
          isOpen={isDateInputOpen}
          onOpen={() => handleOpenModal("dateInput")}
          onClose={() => setIsDateInputOpen(false)}
          placeholder={t("dates")}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>

        <GuestsInputExperiences
          className="flex-1 guests-input-experiences"
          buttonSubmitHref="/listing-experiences"
          validateFields={validateFields}
          isOpen={isGuestsOpen}
          onOpen={() => handleOpenModal("guests")}
        />
      </form>

      <div className="mt-2 text-center">
      </div>
    </div>
  );
};

export default ExperiencesSearchForm;
