import React, { FC } from "react";
import SectionGridHasMap from "./SectionGridHasMap";
import ExperiencesMapSearch from "./ExperiencesMapSearch";
import ExperiencesMoreFiltersMobile from "./TabFilters/ExperiencesMoreFiltersMobile";

interface FullScreenExperiencesMapProps {
  isMapVisible: boolean;
  onClose: () => void;
  mapCenter: [number, number];
  setMapCenter: (coordinates: [number, number]) => void;
  isFiltersModalOpen: boolean;
  setIsFiltersModalOpen: (isOpen: boolean) => void;
  selectedTypes: string[];
  selectedTimeOfDay: string[];
  priceRange: [number, number];
  setSelectedTypes: (types: string[]) => void;
  setSelectedTimeOfDay: (timeOfDay: string[]) => void;
  setPriceRange: (range: [number, number]) => void;
}

const FullScreenExperiencesMap: FC<FullScreenExperiencesMapProps> = ({
  isMapVisible,
  onClose,
  mapCenter,
  setMapCenter,
  isFiltersModalOpen,
  setIsFiltersModalOpen,
  selectedTypes,
  selectedTimeOfDay,
  priceRange,
  setSelectedTypes,
  setSelectedTimeOfDay,
  setPriceRange,
}) => {
  if (!isMapVisible) return null;

  const handleApplyFilters = (
    types: string[],
    timeOfDay: string[],
    range: number[]
  ) => {
    if (range.length === 2) {
      setSelectedTypes(types);
      setSelectedTimeOfDay(timeOfDay);
      setPriceRange([range[0], range[1]] as [number, number]);
      setIsFiltersModalOpen(false);
    } else {
      console.error("El rango de precios debe tener exactamente 2 elementos");
    }
  };

  const handleClearFilters = () => {
    setSelectedTypes([]);
    setSelectedTimeOfDay([]);
    setPriceRange([0, 2000]);
    setIsFiltersModalOpen(false);
  };

  return (
    <div className="fixed inset-0 z-50 bg-white">
      {/* Controles superiores */}
      <div className="absolute top-4 left-14 right-4 flex items-center justify-between z-[60]">
        {/* Botón para abrir filtros */}
        <button
          onClick={() => setIsFiltersModalOpen(true)}
          className="bg-white border border-gray-300 text-gray-500 w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
          aria-label="Open filters"
        >
          <i className="las la-sliders-h text-2xl font-bold"></i>
        </button>

        {/* Barra de búsqueda en el mapa */}
        <div className="flex-1 mx-4">
          <ExperiencesMapSearch
            onLocationSelect={(coordinates) => {
              setMapCenter(coordinates);
            }}
          />
        </div>

        {/* Botón para cerrar el mapa */}
        <button
          onClick={onClose}
          className="bg-gray-500 text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg"
          aria-label="Close map"
        >
          <span className="text-xl font-bold">✕</span>
        </button>
      </div>

      {/* Contenedor del mapa */}
      <div className="w-full h-full">
        <SectionGridHasMap
          isMapVisible={true} // Asegura que el mapa esté visible
          onToggleMap={onClose} // Lógica para cerrar el mapa
          mapCenter={mapCenter}
        />
      </div>

      {/* Modal de filtros */}
      <ExperiencesMoreFiltersMobile
        isOpen={isFiltersModalOpen}
        closeModal={() => setIsFiltersModalOpen(false)}
        selectedTypes={selectedTypes}
        selectedTimeOfDay={selectedTimeOfDay}
        priceRange={priceRange}
        onApply={handleApplyFilters}
        onClear={handleClearFilters}
        className="fixed inset-0 z-[999] bg-white"
      />
    </div>
  );
};

export default FullScreenExperiencesMap;
