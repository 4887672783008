import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string;
  onClick?: () => void;
  text?: string;
  href?: string; // Ruta de redireccionamiento
  disabled?: boolean; // Propiedad para deshabilitar el botón
}

const ButtonSubmit: FC<Props> = ({
  className = "",
  onClick = () => {},
  text = "Search",
  href = "/listing-stay",
  disabled = false,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!disabled) {
      onClick();  // Ejecuta la función pasada como prop

      // Retraso de 1 segundo antes de redirigir y cerrar el modal
      setTimeout(() => {
        if (href) navigate(href); // Redirigir a la ruta proporcionada
      }, 1000);  // 1 segundo de retraso
    }
  };

  return (
    <button
      type="submit"
      onClick={handleClick}  // Cambié el evento aquí
      disabled={disabled}
      className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl flex items-center justify-center text-neutral-50 focus:outline-none ${
        disabled ? "bg-gray-400 cursor-not-allowed" : "bg-primary-400"
      } ${className} relative z-20`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <span className="ml-2">{text}</span>
    </button>
  );
};

export default ButtonSubmit;
