import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import convertNumbThousand from "utils/convertNumbThousand";
import BedsAndBathroomsFilters from "./BedsAndBathroomsFilters";
import MoreFilters from "./MoreFilters";
import MoreFiltersMobile from "./MoreFiltersMobile";
import { useTranslation } from "react-i18next";
import { useFiltersContext } from "contexts/FilterContext";
import MobileFilterMenu from "./MobileFilterMenu";
import SortByMobile from "./SortByMobile";

const typeOfPlaces = [
  { name: "apartment", descriptionKey: "entirePlaceDescription" },
  { name: "house", descriptionKey: "privateRoomDescription" },
  { name: "villa", descriptionKey: "hotelRoomDescription" },
  { name: "hotel", descriptionKey: "sharedRoomDescription" },
  { name: "inn", descriptionKey: "hotelRoomDescription" },
  { name: "motel", descriptionKey: "sharedRoomDescription" },
  { name: "boat", descriptionKey: "sharedRoomDescription" },
];

const puntuacionOptions = [
  { name: "genial", label: "Genial" },
  { name: "muyBien", label: "Muy Bien" },
  { name: "bien", label: "Bien" },
  { name: "agradable", label: "Agradable" },
];

const TabFilters: React.FC<{
  onMapClick: () => void; // Función para abrir/cerrar el mapa
  isMapOpen: boolean; // Estado global del mapa
}> = ({ onMapClick, isMapOpen }) => {
  const { t } = useTranslation("tabFilters");

  // Utiliza el contexto de filtros
  const {
    priceRange,
    setPriceRange,
    selectedTypes,
    setSelectedTypes,
    bedroomsCount,
    setBedroomsCount,
    bathroomsCount,
    setBathroomsCount,
    selectedAmenities,
    setSelectedAmenities,
    selectedFacilities,
    setSelectedFacilities,
    sortBy,
    setSortBy,
    selectedPuntuacion,
    setSelectedPuntuacion,
  } = useFiltersContext();

  // Estados temporales para filtros
  const [tempSortBy, setTempSortBy] = useState(sortBy || "featured");
  const [tempSelectedPlaceTypes, setTempSelectedPlaceTypes] = useState<string[]>(selectedTypes);
  const [tempPriceRange, setTempPriceRange] = useState(priceRange);
  const [tempSelectedPuntuacion, setTempSelectedPuntuacion] = useState<string[]>(selectedPuntuacion);

  const [isSortByMobileOpen, setIsSortByMobileOpen] = useState(false);

  const openSortByMobile = () => setTimeout(() => setIsSortByMobileOpen(true), 100);
  const closeSortByMobile = () => setIsSortByMobileOpen(false);

  // Estados para manejo de modales
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false); // Modal de filtros (escritorio)
  const [isMoreFiltersMobileOpen, setIsMoreFiltersMobileOpen] = useState(false); // Modal de filtros (móvil)

  // Funciones para abrir/cerrar modales
  const openFilters = () => setIsMoreFiltersOpen(true);
  const closeFilters = () => setIsMoreFiltersOpen(false);

  const openFiltersMobile = () => {
    setTimeout(() => setIsMoreFiltersMobileOpen(true), 100); // 200 ms de retraso
  };

  const closeFiltersMobile = () => setIsMoreFiltersMobileOpen(false);

  const sortOptions = [
    { value: "featured", label: t("featured") },
    { value: "highestPrice", label: t("highestPrice") },
    { value: "lowestPrice", label: t("lowestPrice") },
    { value: "reviewStart", label: t("bestValue") },
    { value: "reviewCount", label: t("topRated") },
  ];

  const handleApplySort = () => {
    setSortBy(tempSortBy);
  };

  useEffect(() => {
    setTempSelectedPlaceTypes(selectedTypes);
  }, [selectedTypes]);

  // Maneja el cambio de tipos de lugar
  const handleTypeChange = (type: string) => {
    setTempSelectedPlaceTypes((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((t) => t !== type)
        : [...prevTypes, type]
    );
  };

  // Aplica los tipos de lugar seleccionados temporalmente
  const handleApply = () => {
    setSelectedTypes(tempSelectedPlaceTypes);
  };

  const handlePuntuacionChange = (puntuacion: string) => {
    setTempSelectedPuntuacion((prevPuntuacion) =>
      prevPuntuacion.includes(puntuacion)
        ? prevPuntuacion.filter((p) => p !== puntuacion)
        : [...prevPuntuacion, puntuacion]
    );
  };

  const handleApplyPuntuacion = () => {
  };

  const renderTabsTypeOfPlace = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
          >
            <span>{t("typeOfPlace")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {typeOfPlaces.map((item) => (
                    <div key={item.name}>
                      <Checkbox
                        name={item.name}
                        label={t(item.name)}
                        subLabel={t(item.descriptionKey)}
                        checked={tempSelectedPlaceTypes.includes(item.name)}
                        onChange={() => handleTypeChange(item.name)}
                      />
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempSelectedPlaceTypes([])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  const renderTabsPriceRange = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-400 bg-primary-50 text-primary-400 focus:outline-none">
            <span>{`$${convertNumbThousand(tempPriceRange[0])} - $${convertNumbThousand(tempPriceRange[1])}`}</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-8">
                  <span className="font-medium">{t("pricePerDay")}</span>
                  <Slider
                    range
                    min={0}
                    max={2000}
                    value={tempPriceRange}
                    allowCross={false}
                    onChange={(value) => setTempPriceRange(value as number[])}
                  />
                  <div className="flex justify-between space-x-5">
                    <div className="w-1/2">
                      <label htmlFor="minPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("minPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="minPrice"
                          id="minPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={tempPriceRange[0]}
                          min={0}
                          max={tempPriceRange[1]}
                          onChange={(e) => setTempPriceRange([Number(e.target.value), tempPriceRange[1]])}
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="maxPrice" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                        {t("maxPrice")}
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-neutral-500 sm:text-sm">$</span>
                        </div>
                        <input
                          type="number"
                          name="maxPrice"
                          id="maxPrice"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900 dark:bg-neutral-800 dark:border-neutral-700"
                          value={tempPriceRange[1]}
                          min={tempPriceRange[0]}
                          max={2000}
                          onChange={(e) => setTempPriceRange([tempPriceRange[0], Number(e.target.value)])}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={() => setTempPriceRange([0, 2000])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      setPriceRange(tempPriceRange);
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  const renderSortDropdown = () => {
    const getButtonClasses = (isSelected: boolean) =>
      `text-left px-4 py-2 hover:bg-neutral-100 rounded flex items-center ${isSelected ? "font-semibold text-primary-500" : "text-neutral-700"
      }`;

    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
              aria-haspopup="menu"
              aria-expanded={open}
            >
              <span>{sortBy ? sortOptions.find((option) => option.value === sortBy)?.label : t("sortBy")}</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-10 max-w-max px-4 mt-3 left-0 sm:px-0"
                role="menu"
              >
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                  <ul className="relative flex flex-col px-5 py-2 space-y-2">
                    {sortOptions.map((option) => (
                      <li key={option.value}>
                        <button
                          className={getButtonClasses(tempSortBy === option.value)}
                          onClick={() => setTempSortBy(option.value)} // Actualiza el estado temporal
                          role="menuitem"
                        >
                          <i className={`mr-2 ${getIconForOption(option.value)}`} /> {/* Ícono para cada opción */}
                          {option.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="p-5 bg-neutral-50 flex items-center justify-between space-x-4">
                    <ButtonThird
                      onClick={() => {
                        setTempSortBy(""); // Limpiar el orden
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("clear")}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleApplySort(); // Aplica el orden
                        close(); // Cierra el popover
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("apply")}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // Función para retornar el ícono según la opción seleccionada
  const getIconForOption = (value: string) => {
    switch (value) {
      case "lowestPrice":
        return "las la-sort-amount-up"; // Ícono de precio más bajo
      case "highestPrice":
        return "las la-sort-amount-down"; // Ícono de precio más alto
      case "reviewStart":
        return "las la-thumbs-up"; // Ícono de reviewStart
      case "reviewCount":
        return "las la-star"; // Ícono de reviewCount
      case "featured":
        return "las la-certificate"; // Ícono de Destacado (certificado)
      default:
        return ""; // Sin ícono
    }
  };

  const renderTabsPuntuacion = () => (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 hover:border-neutral-400 focus:outline-none ${open ? "!border-primary-500 " : ""}`}
          >
            <span>{t("Puntuación")}</span>
            <i className="las la-angle-down ml-2"></i>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-auto max-w-md px-4 mt-3 left-0 sm:px-0">
              <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200">
                <div className="relative flex flex-col px-5 py-6 space-y-5">
                  {puntuacionOptions.map((item) => (
                    <div key={item.name} className="flex items-center space-x-3">
                      <Checkbox
                        name={item.name}
                        checked={tempSelectedPuntuacion.includes(item.name)}
                        onChange={() => handlePuntuacionChange(item.name)}
                        className="mr-3"
                      />
                      <span>{item.label}</span>
                    </div>
                  ))}
                </div>
                <div className="p-5 bg-neutral-50 flex items-center justify-between space-x-4"> {/* Added space-x-4 to increase distance */}
                  <ButtonThird
                    onClick={() => setTempSelectedPuntuacion([])}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("clear")}
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={() => {
                      handleApplyPuntuacion();
                      close();
                    }}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    {t("apply")}
                  </ButtonPrimary>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );

  return (
    <div>
      {/* Filtros para escritorio */}
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfPlace()}
        {renderTabsPriceRange()}
        {renderSortDropdown()}
        {renderTabsPuntuacion()}
        <BedsAndBathroomsFilters
          setBedroomsCount={setBedroomsCount}
          setBathroomsCount={setBathroomsCount}
        />
        <MoreFilters
          isOpen={isMoreFiltersOpen}
          openModal={openFilters}
          closeModal={closeFilters}
          selectedAmenities={selectedAmenities}
          selectedFacilities={selectedFacilities}
          onApply={(amenities, facilities) => {
            setSelectedAmenities(amenities);
            setSelectedFacilities(facilities);
            closeFilters();
          }}
          onClear={() => {
            setSelectedAmenities([]);
            setSelectedFacilities([]);
          }}
        />
      </div>

      {/* Barra de menú móvil */}
      <div className="lg:hidden px-6">
        <MobileFilterMenu
          onFiltersClick={openFiltersMobile}
          onSortClick={openSortByMobile} // Lógica para abrir el modal
          onMapClick={onMapClick}
          isMapOpen={isMapOpen}
        />
      </div>

      {/* Modal de filtros móviles */}
      {isMoreFiltersMobileOpen && ( // Usa el estado específico para el modal móvil
        <MoreFiltersMobile
          isOpen={isMoreFiltersMobileOpen}
          closeModal={closeFiltersMobile}
          selectedAmenities={selectedAmenities}
          selectedFacilities={selectedFacilities}
          onApply={(amenities, facilities) => {
            setSelectedAmenities(amenities);
            setSelectedFacilities(facilities);
            closeFiltersMobile(); // Cierra el modal móvil
          }}
          onClear={() => {
            // Limpia amenities y facilities
            setSelectedAmenities([]);
            setSelectedFacilities([]);
            // Limpia los otros estados manualmente si lo deseas
            setSelectedTypes([]);
            setPriceRange([0, 2000]);
            setSelectedPuntuacion([]);
            setBedroomsCount(0);
            setBathroomsCount(0);
          }}
        />
      )}
      {/* Modal de "Sort By" para móvil */}
      {isSortByMobileOpen && (
        <SortByMobile
          isOpen={isSortByMobileOpen}
          closeModal={closeSortByMobile}
          selectedSortBy={sortBy}
          onApply={(sortBy) => {
            setSortBy(sortBy);
            closeSortByMobile(); // Cierra el modal móvil
          }}
        />
      )}
    </div>
  );
};

export default TabFilters;