import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { openDB } from "idb";

interface LocationContextType {
  location: string;
  locationCountry: string;
  setLocation: (location: string) => void;
  setLocationCountry: (country: string) => void;
}

const DB_NAME = "LocationDB";
const STORE_NAME = "location";

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [location, setLocationState] = useState<string>("");
  const [locationCountry, setLocationCountryState] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const loadLocationFromDB = async () => {
    const db = await initDB();
    const savedLocation = await db.get(STORE_NAME, 1);
    return savedLocation || { location: "", locationCountry: "" };
  };

  const saveLocationToDB = async (data: { location: string; locationCountry: string }) => {
    const db = await initDB();
    await db.put(STORE_NAME, { id: 1, ...data });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      const savedLocation = await loadLocationFromDB();
      setLocationState(savedLocation.location);
      setLocationCountryState(savedLocation.locationCountry);
      setIsLoading(false);
    };
    fetchLocation();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveLocationToDB({ location, locationCountry });
    }
  }, [location, locationCountry, isLoading]);

  const setLocation = (newLocation: string) => setLocationState(newLocation);
  const setLocationCountry = (newLocationCountry: string) => setLocationCountryState(newLocationCountry);

  if (isLoading) {
    return null; // Renderiza un spinner o placeholder mientras se cargan los datos
  }

  return (
    <LocationContext.Provider value={{ location, locationCountry, setLocation, setLocationCountry }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocationContext must be used within a LocationProvider");
  }
  return context;
};
