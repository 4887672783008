import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { RoomDataType } from "data/types"; // Define un tipo adecuado para los datos de __rooms
import __rooms from "data/jsons/__rooms.json";

export interface RoomCardProps {
    className?: string;
    data?: RoomDataType;
}

const DEMO_DATA = __rooms[0];

const RoomCard: FC<RoomCardProps> = ({
    className = "",
    data = DEMO_DATA,
}) => {
    const {
        galleryImgs,
        title,
        href,
        id,
        like,
        saleOff,
        price,
    } = data;

    const [showAllIcons, setShowAllIcons] = useState(false);
    const [showAllSecondaryIcons, setShowAllSecondaryIcons] = useState(false);

    // Íconos para la primera sección
    const ICONS = [
        { icon: "las la-door-open", label: "Habitación" },
        { icon: "las la-ruler", label: "25 m²" },
        { icon: "las la-snowflake", label: "Aire acondicionado" },
        { icon: "las la-bath", label: "Baño en la habitación" },
        { icon: "las la-tv", label: "TV de pantalla plana" },
        { icon: "las la-volume-mute", label: "Insonorización" },
        { icon: "las la-coffee", label: "Cafetera" },
        { icon: "las la-wine-bottle", label: "Minibar" },
        { icon: "las la-wifi", label: "WiFi gratis" },
    ];

    // Textos adicionales para la segunda sección
    const SECONDARY_INFO = [
        { icon: "las la-utensils", label: "Desayuno fantástico por € 25" },
        { icon: "las la-calendar-check", label: "Cancelación gratis antes del 19 de diciembre de 2024" },
        { icon: "las la-credit-card", label: "Pagas por adelantado" },
    ];

    const renderSliderGallery = () => {
        if (!galleryImgs || galleryImgs.length === 0) {
            console.error("GallerySlider requires valid gallery images");
            return null; // No renderizar si no hay imágenes
        }

        const uniqueID = `RoomCard_${id || Math.random().toString(36).substr(2, 9)}`;

        return (
            <div id={uniqueID} className="relative flex-shrink-0 w-40 sm:w-48 overflow-hidden">
                <GallerySlider
                    ratioClass="aspect-w-6 aspect-h-6"
                    galleryImgs={galleryImgs}
                    uniqueID={uniqueID}
                    href={href}
                />
                <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
                {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className="flex items-center justify-between px-3 py-2">
                <h2 className="text-base font-medium capitalize line-clamp-1">
                    {title}
                </h2>
                <div className="text-sm font-semibold text-secondary-500">
                    {price}
                    <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
                        /night
                    </span>
                </div>
            </div>
        );
    };

    const renderIcons = () => {
        const iconsToShow = showAllIcons ? ICONS : ICONS.slice(0, 4);

        return (
            <div className="flex flex-wrap gap-2 mt-2">
                {iconsToShow.map((item, index) => (
                    <div
                        key={index}
                        className="flex items-center space-x-1 text-xs text-neutral-700 dark:text-neutral-300"
                    >
                        <i className={`${item.icon} text-sm`}></i>
                        <span>{item.label}</span>
                    </div>
                ))}
                {!showAllIcons && ICONS.length > 4 && (
                    <button
                        onClick={() => setShowAllIcons(true)}
                        className="text-blue-500 text-xs font-semibold"
                    >
                        Más
                    </button>
                )}
            </div>
        );
    };

    const renderSecondaryInfo = () => {
        const secondaryIconsToShow = showAllSecondaryIcons
            ? SECONDARY_INFO
            : SECONDARY_INFO.slice(0, 2);

        return (
            <div className="flex flex-wrap gap-2 mt-4">
                {secondaryIconsToShow.map((item, index) => (
                    <div
                        key={index}
                        className="flex items-center space-x-1 text-xs text-neutral-700 dark:text-neutral-300"
                    >
                        <i className={`${item.icon} text-sm`}></i>
                        <span>{item.label}</span>
                    </div>
                ))}
                {!showAllSecondaryIcons && SECONDARY_INFO.length > 2 && (
                    <button
                        onClick={() => setShowAllSecondaryIcons(true)}
                        className="text-blue-500 text-xs font-semibold"
                    >
                        Más
                    </button>
                )}
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="mt-auto flex justify-end p-3">
                <button className="px-3 py-1 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600 transition">
                    Reservar
                </button>
            </div>
        );
    };

    return (
        <div
            className={`nc-RoomCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-xl overflow-hidden hover:shadow-lg transition-shadow ${className}`}
            data-nc-id="RoomCard"
        >
            <div className="flex">
                {/* Imagen a la izquierda */}
                <div className="relative flex-shrink-0 w-40 sm:w-48 overflow-hidden">
                    {renderSliderGallery()}
                </div>

                {/* Contenido a la derecha */}
                <div className="flex flex-col flex-grow">
                    {/* Título y precio arriba */}
                    {renderHeader()}

                    {/* Primera sección de íconos */}
                    <div className="flex-grow px-3">
                        {renderIcons()}
                    </div>

                    {/* Línea divisoria */}
                    <div className="border-t border-neutral-200 dark:border-neutral-700 my-2"></div>

                    {/* Segunda sección de textos */}
                    <div className="px-3">
                        {renderSecondaryInfo()}
                    </div>

                    {/* Botón abajo */}
                    {renderFooter()}
                </div>
            </div>
        </div>
    );
};

export default RoomCard;
