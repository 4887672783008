import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { openDB } from "idb";

interface GuestsContextType {
  adults: number;
  children: number;
  infants: number;
  setAdults: (value: number) => void;
  setChildren: (value: number) => void;
  setInfants: (value: number) => void;
}

const DB_NAME = "GuestsDB";
const STORE_NAME = "guests";

const GuestsContext = createContext<GuestsContextType | undefined>(undefined);

export const GuestsProvider: React.FC<{ children: ReactNode }> = ({ children: contextChildren }) => {
  const [adults, setAdultsState] = useState<number>(1);
  const [children, setChildrenState] = useState<number>(0);
  const [infants, setInfantsState] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const initDB = async () => {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const loadGuestsFromDB = async () => {
    const db = await initDB();
    const savedGuests = await db.get(STORE_NAME, 1);
    return savedGuests || { adults: 1, children: 0, infants: 0 };
  };

  const saveGuestsToDB = async (data: { adults: number; children: number; infants: number }) => {
    const db = await initDB();
    await db.put(STORE_NAME, { id: 1, ...data });
  };

  useEffect(() => {
    const fetchGuests = async () => {
      const savedGuests = await loadGuestsFromDB();
      setAdultsState(savedGuests.adults);
      setChildrenState(savedGuests.children);
      setInfantsState(savedGuests.infants);
      setIsLoading(false);
    };
    fetchGuests();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveGuestsToDB({ adults, children, infants });
    }
  }, [adults, children, infants, isLoading]);

  const setAdults = (value: number) => setAdultsState(Math.max(value, 1));
  const setChildren = (value: number) => setChildrenState(value);
  const setInfants = (value: number) => setInfantsState(value);

  if (isLoading) {
    return null; // Renderiza un spinner o nada mientras se cargan los datos
  }

  return (
    <GuestsContext.Provider value={{ adults, children, infants, setAdults, setChildren, setInfants }}>
      {contextChildren}
    </GuestsContext.Provider>
  );
};

export const useGuestsContext = () => {
  const context = useContext(GuestsContext);
  if (context === undefined) {
    throw new Error("useGuestsContext debe usarse dentro de un GuestsProvider");
  }
  return context;
};
