import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";

interface SortByMobileProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedSortBy: string;
  onApply: (sortBy: string) => void;
}

const SortByMobile: React.FC<SortByMobileProps> = ({
  isOpen,
  closeModal,
  selectedSortBy,
  onApply,
}) => {
  const { t } = useTranslation("tabFilters");

  const sortOptions = [
    { value: "featured", label: t("featured") },
    { value: "highestPrice", label: t("highestPrice") },
    { value: "lowestPrice", label: t("lowestPrice") },
    { value: "reviewCount", label: t("topRated") },
    { value: "reviewStart", label: t("bestValue") },
  ];

  const getIconForOption = (value: string) => {
    switch (value) {
      case "lowestPrice":
        return "las la-sort-amount-up"; // Ícono de precio más bajo
      case "highestPrice":
        return "las la-sort-amount-down"; // Ícono de precio más alto
      case "reviewStart":
        return "las la-thumbs-up"; // Ícono de mejor valorados
      case "reviewCount":
        return "las la-star"; // Ícono de más valoraciones
      case "featured":
        return "las la-certificate"; // Ícono de destacados
      default:
        return ""; // Sin ícono
    }
  };

  const [tempSortBy, setTempSortBy] = React.useState(selectedSortBy);

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-flex flex-col w-full max-w-md p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                {t("sortByTitle")}
              </Dialog.Title>
              <div className="mt-4 space-y-4">
                {sortOptions.map((option) => (
                  <button
                    key={option.value}
                    className={`w-full flex items-center text-left px-4 py-2 rounded-md ${
                      tempSortBy === option.value
                        ? "bg-primary-100 text-primary-700"
                        : "hover:bg-neutral-100"
                    }`}
                    onClick={() => setTempSortBy(option.value)}
                  >
                    <i className={`mr-3 ${getIconForOption(option.value)}`} />
                    {option.label}
                  </button>
                ))}
              </div>
              <div className="mt-6 flex justify-between">
                <ButtonThird
                  onClick={() => setTempSortBy("")} // Limpia la selección
                >
                  {t("clear")}
                </ButtonThird>
                <ButtonPrimary
                  onClick={() => {
                    onApply(tempSortBy); // Aplica el orden seleccionado
                    closeModal();
                  }}
                >
                  {t("apply")}
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SortByMobile;
